import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Restaurant from './components/Restaurant'
import RestaurantTable from './components/RestaurantTable'
import RestaurantKiosk from './components/RestaurantKiosk'
import Home from './components/Home'
import Cart from './components/Cart'
import Confirm from './components/Confirm'
// import Tracking from './components/Tracking'

const AppRouter = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/kiosk/:id" component={RestaurantKiosk} />
    <Route exact path="/qr/:id" component={RestaurantTable} />
    <Route exact path="/:id/" component={Restaurant} />
    <Route exact path="/:id/cart" component={Cart} />
    <Route exact path="/:id/confirm" component={Confirm} />
    <Route exact path="/:id/:orderType" component={Restaurant} />
    <Redirect to="/" />
  </Switch>
)

export default AppRouter
