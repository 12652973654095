import {
  SET_RECOMMENDATION1,
  SET_RECOMMENDATION2,
  FLUSH_RECOMMENDATIONS,
  INCREMENT_RECOMMENDATION_COUNT,
  SET_HAS_REFUSED
} from '../actions/recommendationAction';

const recommendationObject = {
  recommendation1: null,
  recommendation2: null,
  hasRefused: false,
  count: 0,
}

export default function recommendationReducer(state = { ...recommendationObject }, action) {
  switch (action.type) {
    case SET_RECOMMENDATION1:
      return {
        ...state,
        recommendation1: action.payload.recommendation1,
      }
    case SET_RECOMMENDATION2:
      return {
        ...state,
        recommendation2: action.payload.recommendation2,
      }
    case INCREMENT_RECOMMENDATION_COUNT:
      return {
        ...state,
        count: state.count + 1,
      }
    case SET_HAS_REFUSED:
      return {
        ...state,
        hasRefused: true,
      }
    case FLUSH_RECOMMENDATIONS:
      return {
        ...recommendationObject
      }
    default:
      return state;
  }
}
