import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { app } from '../services/firebase'
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.



function StripePaymentButton(props) {
  const total = props.cartTotal;
  const urlName = props.urlName;
  const disabled = props.disabled;
  const stripePubKey = props.stripePubkey
  const stripePromise = process.env.NODE_ENV !== 'production' ? loadStripe(process.env[`REACT_APP_PK_TEST`]) : loadStripe(stripePubKey, { stripeAccount: props.stripeAccountId })
  const handleClick = async (event) => {
    event.preventDefault();
    if (disabled) return;

    // Get Stripe.js instance
    const orderId = await props.confirmCart(event);
    if (!orderId) {
      return;
    }

    const stripe = await stripePromise;

    // Call your backend to create the Checkout Session
    const instance = app().functions('europe-west1').httpsCallable(process.env.NODE_ENV !== 'production' ? 'demoCreateCheckoutSession' : 'createCheckoutSession')
    const response = await instance({
      urlName,
      total,
      orderId,
    });

    const session = response.data;

    if (session.error) {
      return;
    }

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };

  return (
    <button
      role="link"
      className={`confirm-btn ${disabled ? 'disabled' : ''}`}
      id="recaptcha-container"
      onClick={handleClick}
      disabled={disabled}
    >
      Payer en ligne
    </button>
  );
}

export default StripePaymentButton;
