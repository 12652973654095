import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'bootstrap/dist/css/bootstrap.min.css'
import './localize/i18n'

import packageJson from '../package.json';
const version = packageJson.version;


Sentry.init({
  dsn: "https://4a329b08d19d4695b2f53e6a16daabbc@o503181.ingest.sentry.io/5587901",
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 1.0,
  release: "we-eats-order@" + version,
  beforeSend(event, hint) {
    const error = hint.originalException;
    if ( error && error.message) {
      return event
    }
    return null
  }
});


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
