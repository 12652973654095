// @flow
import React from 'react';
import isBefore from 'date-fns/isBefore'
import isEqual from 'date-fns/isEqual'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import addMinutes from 'date-fns/addMinutes'
import addSeconds from 'date-fns/addSeconds'

import '../styles/TimePicker.scss'

type Props = {
  selected: Date,
  creneaux: Array,
  onChange: Function
}

class TimePicker extends React.Component<Props> {
  render() {
    const offset = this.props.offset || 0;
    const offsetNow = addMinutes(new Date(), offset);

    if(this.props.selected === null || !this.props.creneaux.length)
      return <div className='timepicker'></div>

    const timeslots = [];
    const dayOfTheWeek = this.props.selected.getDay() ? this.props.selected.getDay() - 1 : 6
    for (let i = 0; i < this.props.creneaux[dayOfTheWeek].length; ++i) {
      timeslots.push([]);
      const creneau = this.props.creneaux[dayOfTheWeek][i];

      let startDate = parse(creneau.start, 'HH:mm', this.props.selected)
      const endDate = parse(creneau.end, 'HH:mm', this.props.selected)
      timeslots[i].push(startDate);

      while(startDate < endDate) {
        startDate = addMinutes(startDate, 15);
        timeslots[i].push(startDate);
      }
    }

    return (
      <div className='timepicker'>
        {timeslots.map((t, i) => (
          <div
            key={i}
            className='timepicker-slot'
          >
            {t.map(date => isBefore(addSeconds(date, 59), offsetNow) ? (
              <span
                className={'disabled'}
                key={date.getTime()}
              >{format(date, 'HH:mm')}</span>
            ) : (
              <span
                className={
                  isEqual(this.props.selected, date) && 'selected'
                }
                key={date.getTime()}
                onClick={() => this.props.onChange(date)}
              >{format(date, 'HH:mm')}</span>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

export default TimePicker;
