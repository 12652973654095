import { combineReducers } from 'redux';

import cartReducer from './cartReducer';
import settingsReducer from './settingsReducer';
import customerDetailsReducer from './customerDetailsReducer';
import recommendationReducer from './recommendationReducer';
import colorsReducer from './colorsReducer'

const rootReducer = combineReducers({
  cart: cartReducer,
  settings: settingsReducer,
  customerDetails: customerDetailsReducer,
  recommendation: recommendationReducer,
  colors: colorsReducer,
});

export default rootReducer;