export const ADD_PRODUCT = "ADD_PRODUCT"
export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const UPDATE_COMMENT = "UPDATE_COMMENT"
export const UPDATE_TYPE = "UPDATE_TYPE"
export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const UPDATE_CART_TOTAL = "UPDATE_CART_TOTAL"
export const UPDATE_RESTO_INFO = "UPDATE_RESTO_INFO"
export const SET_FLUSH_CART = "SET_FLUSH_CART"
export const RESET_CART = "RESET_CART"
export const UPDATE_CART_PICKUP_DATE = "UPDATE_CART_PICKUP_DATE"
export const APPLY_DISCOUNT = "APPLY_DISCOUNT"
export const RETURN_CART_FEES = "RETURN_CART_FEES"
export const SET_CART_TOTAL_CONFIRM = "SET_CART_TOTAL_CONFIRM"
export const APPLY_COUPON = "APPLY_COUPON"
export const PICKED_ORDER_TYPE = "PICKED_ORDER_TYPE"
export const UPDATE_TABLE = "UPDATE_TABLE"
export const UPDATE_QR_ID = "UPDATE_QR_ID"
export const SET_DISCOUNT_SETTINGS = "SET_DISCOUNT_SETTINGS"

export function addProduct(product) {
  return {
    type: ADD_PRODUCT,
    payload: {
      product
    }
  }
}

export function updateComment(comment) {
  return {
    type: UPDATE_COMMENT,
    payload: {
      comment
    }
  }
}

export function updateType(orderType) {
  return {
    type: UPDATE_TYPE,
    payload: {
      orderType
    }
  }
}

export function updateProduct(product, idx) {
  return {
    type: UPDATE_PRODUCT,
    payload: {
      product,
      idx
    }
  }
}

export function deleteProduct(idx) {
  return {
    type: DELETE_PRODUCT,
    payload: {
      idx
    }
  }
}

export function updateCartTotal(cartTotal, cartTotalHT, cartTotalVat, cartTotalProducts, cartSubtotal) {
  return {
    type: UPDATE_CART_TOTAL,
    payload: {
      cartTotal,
      cartTotalHT,
      cartTotalVat,
      cartTotalProducts,
      cartSubtotal,
    }
  }
}

export function updateCartPickupDate(pickupDate) {
  return {
    type: UPDATE_CART_PICKUP_DATE,
    payload: {
      pickupDate,
    }
  }
}

export function updateRestoInfo(restoId, restoName, restoPostal, canOrder, restoInformations) {
  return {
    type: UPDATE_RESTO_INFO,
    payload: {
      restoId,
      restoName,
      restoPostal,
      canOrder,
      restoInformations,
    }
  }
}

export function applyDiscount(discount) {
  return {
    type: APPLY_DISCOUNT,
    payload: {
      discount,
    }
  }
}

export function updateCartFees(discountTotal, feeTotal) {
  return {
    type: RETURN_CART_FEES,
    payload: {
      discountTotal,
      feeTotal,
    }
  }
}

export function setFlushCart() {
  return {
    type: SET_FLUSH_CART
  }
}

export function resetCart() {
  return {
    type: RESET_CART
  }
}

export function setCartTotalConfirm(totalConfirm) {
  return {
    type: SET_CART_TOTAL_CONFIRM,
    payload: {
      totalConfirm
    }
  }
}

export function applyCoupon(coupon) {
  return {
    type: APPLY_COUPON,
    payload: {
      coupon,
    }
  }
}

export function pickedOrderType() {
  return {
    type: PICKED_ORDER_TYPE,
    payload: {
      pickedOrderType: true
    }
  }
}

export function updateTable(table) {
  return {
    type: UPDATE_TABLE,
    payload: {
      table
    }
  }
}

export function updateQrId(qrId) {
  return {
    type: UPDATE_QR_ID,
    payload: {
      qrId,
    },
  }
}

export function setDiscountSettings(settings) {
  return {
    type: SET_DISCOUNT_SETTINGS,
    payload: {
      settings,
    },
  }
}