/**
 * @format
 * @flow
 */

import React, { Component } from 'react'
import { Breakpoint } from 'react-socks'
import { Container, Form, Modal, Col, Button } from 'react-bootstrap'
import { Redirect } from "react-router-dom"
import { connect } from 'react-redux'
import { updateComment, updateType, resetCart, updateRestoInfo, setFlushCart, updateCartPickupDate, applyDiscount, updateCartFees, setCartTotalConfirm, applyCoupon, setDiscountSettings } from '../actions/cartAction'
import { updateRestoSettings, updateDeliverySchedule, updateOrderSchedule, updateDeliverySettings, updateTakeawaySettings } from '../actions/settingsAction';
import Lottie from 'react-lottie'
import Swal from 'sweetalert2';
import * as animationData from '../assets/loading.json'
import StripePaymentButton from './StripePaymentButton'
import InputMask from "react-input-mask"
import { FaCheckCircle } from "react-icons/fa";

import ProductDetail from './ProductDetail'
import { CartType } from '../flowObjectType'
import { getOrderType, parseSchedule, computeDiscount, paymentIdToName } from './Utils'
import $ from "jquery"
import dayjs from 'dayjs'
import ContentLoader from 'react-content-loader'

import ProductCartRow from './ProductCartRow'
import { firestore, auth } from '../services/firebase'
import '../styles/Cart.scss'

import Checkbox from './Checkbox'
import TopBar from './TopBar'
import FooterBar from './FooterBar'

import TimePicker from './TimePicker';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import addMinutes from 'date-fns/addMinutes';
import addSeconds from 'date-fns/addSeconds';
import formatRelative from 'date-fns/formatRelative';
import { fr } from 'date-fns/locale'
import { setCustomerDetails } from '../actions/customerDetailsAction'

import isEqual from 'lodash/isEqual';

require('dayjs/locale/fr')
dayjs.locale('fr')


//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
function calcCrow(lat1, lon1, lat2, lon2) {
  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  var lat1 = toRad(lat1);
  var lat2 = toRad(lat2);

  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}

// Converts numeric degrees to radians
function toRad(Value) {
  return Value * Math.PI / 180;
}

type Props = {
  match: Object,
  cart: CartType,
  customerDetails: Object,
  updateComment: Function,
  updateType: Function,
  updateRestoInfo: Function,
  setCustomerDetails: Function,
  updateRestoSettings: Function,
  updateOrderSchedule: Function,
  updateDeliverySchedule: Function,
  updateDeliverySettings: Function,
  updateTakeawaySettings: Function,
  resetCart: Function,
  settings: Object,
  applyDiscount: Function,
  setCartTotalConfirm: Function,
  setDiscountSettings: Function
}

type State = {
  modalVisible: boolean,
  showProductDetail: boolean,
  orderType: ?number,
  selectedIdx: number,
  codeInput: string,
  userComment: string,
  userFirstName: string,
  userLastName: string,
  userEmail: string,
  userPhone: string,
  userAt: string,
  userAddress: string,
  userAddressComplement: string,
  userPostal: ?number,
  codeInput: string,
  showCodeVerif: boolean,
  showOrderConfirmed: boolean,
  pickupDate: Date,
  hasPickedTime: boolean,
  deliveryPostal: Array<{}>,
  rememberMe: boolean,
  loading: boolean,
  stripePayment: boolean,
  paymentMethod: string,
  couponCode: string,
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

class Cart extends Component<Props, State> {
  unsubscribe: Object
  refRestaurant: Object
  id: string
  commentRef: Object
  userId: string
  code: Object
  dateAvailable: boolean
  canPreOrder: boolean

  constructor(props) {
    super(props);
    this.commentRef = React.createRef()
    this.userId = ''
    this.code = null
    this.refRestaurant = null
    this.dateAvailable = true
    const customer = props.customerDetails.rememberMe ? props.customerDetails : {};

    let userPostal = null;
    if (props.customerDetails.rememberMe && props.settings.deliverySettings.deliveryPostal) {
      for (let i = 0; i < props.settings.deliverySettings.deliveryPostal.length; ++i) {
        if (isEqual(props.settings.deliverySettings.deliveryPostal[i], props.customerDetails.postal)) {
          userPostal = i;
          break;
        }
      }
    }

    this.state = {
      showProductDetail: false,
      modalVisible: false,
      orderType: this.props.cart.orderType,
      selectedIdx: 0,
      userComment: '',
      userFirstName: customer.firstName || '',
      userLastName: customer.lastName || '',
      userEmail: customer.email || '',
      userPhone: customer.phone || '',
      userAddress: customer.address || '',
      userAddressComplement: customer.addressComplement || '',
      userPostal,
      userAt: 'now',
      codeInput: '',
      showCodeVerif: false,
      showOrderConfirmed: false,
      pickupDate: null,
      hasPickedTime: false,
      deliveryPostal: [],
      rememberMe: customer.rememberMe || false,
      loading: false,
      stripePayment: false,
      paymentMethod: null,
      couponCode: '',
      cartOptions: null
    }

    this.canPreOrder = this.state.orderType === 1 ? (this.props.settings.takeawaySettings.preOrder === undefined || this.props.settings.takeawaySettings.preOrder) :
      this.state.orderType === 4 ? (this.props.settings.deliverySettings.preOrder === undefined || this.props.settings.deliverySettings.preOrder) : true

  }

  componentDidMount() {
    if (this.props.cart.flushCart === true) this.props.resetCart()
    const orderType = this.props.cart.orderType
    $(window).scrollTop(0)
    auth().onAuthStateChanged(function (user) {
      if (user) {

      } else {

      }
    });

    if (this.props.cart.restoId !== 'lU5wBc5zQHIauDZHBiRk' && this.props.cart.restoId !== '7ENqBTYLfJAFri8Iupm6') {
      window.recaptchaVerifier = new auth.RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': function (response) {
          console.log(response)
        }
      });
    }

    if (this.props.cart.restoId) {
      this.refRestaurant = firestore().collection('Restaurant').doc(this.props.cart.restoId)
      this.unsubscribe = this.refRestaurant.onSnapshot(this.onRestoUpdate)
    }

    this.setState({
      orderType,
      pickupDate: this.getAvailableDate(orderType),
    })

    setInterval(() => {
      this.forceUpdate()
    }, 10000)

    this.getCartOption()
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe()
  }

  getCartOption = () => {
    const refCartOption = firestore().collection(`Restaurant/${this.props.cart.restoId}/CartOption`)
    refCartOption.get().then(options => {
      const cartOptions = []
      options.forEach((doc) => {
        const { createdAt, ...data } = doc.data()
        if (data.displayApp) cartOptions.push(data)
      })
      this.setState({ cartOptions })
    })
  }

  onRestoUpdate = (querySnapshot: Object) => {
    const restaurant = querySnapshot.data()
    const orderSchedule = parseSchedule(restaurant.settings.orderSchedule);
    const deliverySchedule = parseSchedule(restaurant.settings.deliverySchedule);

    const deliverySettings = restaurant.deliverySettings || {};
    const takeawaySettings = restaurant.takeawaySettings || {};

    this.props.updateDeliverySchedule(deliverySchedule);
    this.props.updateOrderSchedule(orderSchedule);

    this.setState({
      deliveryPostal: deliverySettings.deliveryPostal || [],
    })

    if (!restaurant.settings.delivery && restaurant.settings.takeaway === true)
      this.handleOrderTypeChange(1)
    if (restaurant.settings.delivery === true && !restaurant.settings.takeaway)
      this.handleOrderTypeChange(4)
    if (!restaurant.settings.delivery && !restaurant.settings.takeaway && !restaurant.settings.table) {
      this.handleOrderTypeChange(0)
    }


    this.props.setDiscountSettings({ takeawayDiscount: { discount: this.props.settings.takeawaySettings.discount, discountFrom: this.props.settings.takeawaySettings.discountFrom }, deliveryDiscount: { discount: this.props.settings.deliverySettings.discount, discountFrom: this.props.settings.deliverySettings.discountFrom } })
    this.props.applyDiscount(this.state.orderType === 1 ? takeawaySettings.discount || 0 : this.state.orderType === 4 ? deliverySettings.discount || 0 : 0);
    this.props.updateDeliverySettings(deliverySettings);
    this.props.updateTakeawaySettings(takeawaySettings);
    this.props.updateRestoInfo(restaurant.informations.id, restaurant.informations.name, restaurant.informations.postalCode, restaurant.state.canOrder, restaurant.informations)
    this.props.updateRestoSettings(restaurant.informations.id, restaurant.settings.color, restaurant.settings.autoAcceptOrder, restaurant.settings.delivery, restaurant.settings.takeaway, restaurant.settings.table, restaurant.settings.stripe || '', restaurant.settings.orderTypePopup || false);

    this.canPreOrder = this.state.orderType === 1 ? (this.props.settings.takeawaySettings.preOrder === undefined || this.props.settings.takeawaySettings.preOrder) :
      this.state.orderType === 4 ? (this.props.settings.deliverySettings.preOrder === undefined || this.props.settings.deliverySettings.preOrder) : true
  }

  onClickProduct = (index) => e => {
    this.setState({
      showProductDetail: true,
      selectedIdx: index
    });
  };

  toggleModal = () => {
    this.setState(prevState => ({
      showProductDetail: !prevState.showProductDetail
    }))
  }

  onUpdateComment = () => {
    //redux
    this.props.updateComment(this.state.userComment)
  }

  getFinalProduct = () => {
    const { products } = this.props.cart
    const newProduct = []
    products.forEach((product, index) => {
      const { rubric, ...noRubric } = product
      newProduct[index] = { ...noRubric, rubric: [] };
      (product.rubric || []).forEach((sections) => {
        const rubricData = sections.data.filter((item) => item.checked === true)
        newProduct[index].rubric.push({ rubricName: sections.rubricName, data: rubricData })
      })
    })
    return newProduct
  }

  _getVatDetail = (products, additionalFee) => {
    let vatDetail = {}
    if (additionalFee !== null) {
      vatDetail[additionalFee.vat] = additionalFee.totalVat
    }
    products.forEach(product => {

      let total = product.quantity * product.unitPrice
      let totalHT = product.quantity * product.unitPriceHT
      if (typeof product.discount !== 'undefined' && product.discount !== null && !product.discount.isFidelityDiscount) {
        total = total * (1 - product.discount.percentage / 100)
        totalHT = totalHT * (1 - product.discount.percentage / 100)
      }

      const productVat = total - totalHT
      if (product.vat !== '' && !isNaN(productVat)) {
        if (typeof vatDetail[product.vat] === 'undefined')
          vatDetail[product.vat] = productVat
        else
          vatDetail[product.vat] += productVat
      }

      product.rubric.forEach((section) => {
        section.data.forEach((rubric) => {
          if (rubric.unitPrice > 0) {
            const rubricVat = rubric.unitPrice - rubric.unitPriceHT
            if (rubric.vat !== '' && !isNaN(rubricVat)) {
              if (typeof vatDetail[rubric.vat] === 'undefined')
                vatDetail[rubric.vat] = rubricVat
              else
                vatDetail[rubric.vat] += rubricVat
            }
          }
        })
      })
    })
    return vatDetail
  }

  _submitForm = (event) => {
    if (event && event.preventDefault)
      event.preventDefault()

    if (typeof this.props.cart.canOrder === 'undefined' || this.props.cart.canOrder === false) {
      Swal.fire("Attention", "La commande sur internet est momentanement suspendu", "error");
      return
    }

    if (this.state.userLastName === '' || this.state.userFirstName === '' || this.state.userEmail === '' || this.state.userPhone === '') {
      Swal.fire("Information incomplète", "Veuillez remplir tous les champs avec une étoile *", "warn");
      return
    }

    if (this.state.orderType === 4 && (this.state.userAddress === '' || this.state.userPostal === null)) {
      Swal.fire("Information incomplète", "Veuillez remplir tous les champs concerant votre votre adresse de livraison", "error");
      return
    }
    console.log('TEL:', this.state.userPhone)
    if (this.state.userPhone.indexOf('_') !== -1) {
      Swal.fire("Numero de téléphone invalide", "", "error");
      return
    }

    if (!this.couponCheck(this.props.cart.coupon)) {
      return;
    }

    this.setState({ loading: true });
    if (this.state.stripePayment === false) {
      this._phoneVerification()
    }
    else {
      return this.confirmCart(false);
    }
  }

  _phoneVerification = () => {

    // 05/10/2023 deactivate sms verification
    this.setState({ loading: true })
    setTimeout(() => {
      this.confirmCart(true)
    }, 2500)
    return

    let { userPhone } = this.state;
    auth().languageCode = 'fr';

    // deactivate phone verif with paradis soleil levant
    if (this.props.cart.restoId === 'lU5wBc5zQHIauDZHBiRk' || this.props.cart.restoId === '7ENqBTYLfJAFri8Iupm6' || this.props.cart.restoId === 'hZkAFRN9SIbDIswbnP0v' || this.props.cart.restoId === 'Wryfg8bld9Zd0Xw1IpbE') {
      this.setState({ loading: true })
      setTimeout(() => {
        this.confirmCart(true)
      }, 2500)
      return
    }

    const appVerifier = window.recaptchaVerifier;

    if (auth().currentUser && auth().currentUser.phoneNumber === userPhone.replace('0', '').replace(/ /g, '')) {
      this.setState({ loading: true })
      setTimeout(() => {
        this.confirmCart(true)
      }, 2500)
      return
    }

    auth().setPersistence(auth.Auth.Persistence.LOCAL)
      .then(() => {
        auth().signInWithPhoneNumber(`${userPhone}`, appVerifier)
          .then(code => {
            this.code = code
            this.setState({ showCodeVerif: true, loading: false })
          })
          .catch(error => {
            console.log({ error })
            this.setState({ loading: false }, () => {
              if (error.code === "auth/invalid-phone-number")
                Swal.fire('Numéro de téléphone invalide', '', 'error');
              else if (error.code === "auth/too-many-requests")
                Swal.fire('Nous avons bloqué toutes les demandes de cet appareil en raison d\'une activité inhabituelle. Veuillez réessayez plus tard ou utilisé un autre numéro de téléphone.', '', 'error');
            })

            return false
          })
      })
      .catch(function (error) { });
  };

  createUser = (docRefUser: Object, user: Object) => {
    const id = user.uid

    docRefUser.set({
      id,
      phone: user.phoneNumber,
      firstname: this.state.userFirstName,
      lastname: this.state.userLastName,
      email: this.state.userEmail,
      deviceId: '',
      createdAt: firestore.FieldValue.serverTimestamp(),
      enable: true,
      connectionInfo: {
        connectedAt: firestore.FieldValue.serverTimestamp()
      }
    }, { merge: true })
    this.confirmCart(true)
  }

  validatePhoneCode = () => {
    if (this.state.codeInput !== null || this.state.codeInput !== '') {
      this.setState({ loading: true, showCodeVerif: false });
      this.code.confirm(this.state.codeInput)
        .then((user) => {
          const currentUser = auth().currentUser
          this.userId = currentUser.uid
          this.setState({ codeInput: '', showCodeVerif: false })
          this.code = null
          const docRefUser = firestore().collection('User').doc(currentUser.uid)
          docRefUser.get().then(doc => {
            if (doc.exists) {
              this.confirmCart(true)
            }
            else {
              this.createUser(docRefUser, currentUser)
            }
          })
        })
        .catch(error => {
          Swal.fire('Votre code est invalide, veuillez reessayer ou revoyer un nouveau code', '', 'error');
          this.setState({ loading: false, showCodeVerif: true })
          return false
        })

    } else {

    }
  }

  confirmCart = async (redirect = false) => {
    const { totalProducts, orderType, table, customerNbr } = this.props.cart
    let { total, totalHT, totalVat, discount, coupon } = this.props.cart
    let discountTotal = 0;
    let discountCoupon = 0;
    let additionalFee = {
      total: 0,
      totalHT: 0,
      totalVat: 0,
      vat: 20,
    };

    if (this.props.cart.subtotal - this.props.cart.total)
      discountTotal = this.props.cart.subtotal - this.props.cart.total

    if (coupon !== null) {
      if (coupon?.discountType === 'percent') {
        total = Number.parseFloat(computeDiscount(total, coupon.discountPercent, true).toFixed(2));
        totalHT = Number.parseFloat(computeDiscount(totalHT, coupon.discountPercent, true).toFixed(2));
        totalVat = Number.parseFloat(computeDiscount(totalVat, coupon.discountPercent, true).toFixed(2));
        discountCoupon = computeDiscount(this.props.cart.total, this.props.cart.coupon.discountPercent, false)
      }
      else if (coupon?.discountType === 'price') {
        // TODO: check vat calculation above
        total -= coupon.discountPrice;
        totalHT -= coupon.discountPrice;
        totalVat -= coupon.discountPrice;
        discountCoupon = coupon.discountPrice
      }
    }

    if (this.state.orderType === 4 && !this.isDeliveryFree()) {
      const deliveryFee = this.props.settings.deliverySettings.deliveryPostal[this.state.userPostal].postalFee || 0;

      additionalFee.total = deliveryFee;
      additionalFee.totalHT = deliveryFee / 1.2
      additionalFee.totalVat = additionalFee.total - additionalFee.totalHT
      total += additionalFee.total
      totalHT += additionalFee.totalHT
      totalVat += additionalFee.totalVat
    }

    const products = this.getFinalProduct()

    // const restoName = this.props.match.params.id.toUpperCase().replace(/-/g, '_')
    const restoId = this.props.cart.restoId

    let orderNbr = -1
    const refRestoOrder = firestore().collection(`Restaurant/${restoId}/Order`)
    await firestore().collection(`Restaurant/${restoId}/Order`).where('isClosed', '==', false)
      .get()
      .then(collection => {
        orderNbr = collection.size + 1
      })
      .catch(error => console.log(error))
    let pickUpTime = dayjs().isSame(dayjs(this.state.pickupDate), 'day') ? dayjs(this.state.pickupDate).format('HH:mm') : dayjs(this.state.pickupDate).format('DD MMM HH:mm')
    const orderDate = firestore.FieldValue.serverTimestamp()
    const docRef = refRestoOrder.doc();

    const jsonOrder = {
      paymentIntentId: null,
      createdAt: orderDate,
      confirmedAt: orderDate,
      isPaid: false,
      isClosed: false,
      paidAt: null,
      orderType,
      comment: this.state.userComment,
      cartOption: this.state.cartOptions.filter((o) => (o.checked ?? false)),
      products: Array.from(products),
      productNbr: totalProducts,
      pickUpTime,
      canceledReason: 0,
      customerNbr,
      onlinePayment: this.state.stripePayment,
      orderNbr: orderNbr,
      status: this.props.settings.autoAcceptOrder ? 2 : 1,
      table,
      discountTotal,
      discountCoupon,
      subtotal: this.props.cart.subtotal,
      discount,
      total,
      totalHT,
      totalVat,
      vatDetail: this._getVatDetail(products, additionalFee),
      additionalFee,
      customer: {
        id: this.userId,
        firstname: this.state.userFirstName,
        lastname: this.state.userLastName,
        email: this.state.userEmail,
        phone: this.state.userPhone,
        address: orderType === 4 ? this.state.userAddress : '',
        addressComplement: orderType === 4 ? this.state.userAddressComplement : '',
        postal: orderType === 4 ? `${this.state.deliveryPostal[this.state.userPostal].postalCode}` : '',
        city: orderType === 4 ? `${this.state.deliveryPostal[this.state.userPostal].postalCity}` : '',
        deviceFcmToken: null
      },
      coupon: coupon ?? null,
      payments: [],
      selectedPayment: this.state.paymentMethod
    }

    const customerDetails = {
      id: this.userId,
      firstName: this.state.userFirstName,
      lastName: this.state.userLastName,
      email: this.state.userEmail,
      phone: this.state.userPhone,
      rememberMe: this.state.rememberMe,
      address: this.state.userAddress,
      addressComplement: this.state.userAddressComplement,
      postal: orderType === 4 ? this.state.deliveryPostal[this.state.userPostal] : null,
      postalCode: orderType === 4 ? `${this.state.deliveryPostal[this.state.userPostal].postalCode}` : '',
      city: orderType === 4 ? `${this.state.deliveryPostal[this.state.userPostal].postalCity}` : '',
    };

    this.props.setCustomerDetails(customerDetails);
    this.props.updateCartPickupDate(this.state.pickupDate);
    this.props.setCartTotalConfirm(total);
    this.props.updateCartFees(discountTotal, additionalFee.total);
    // this.props.setFlushCart()
    docRef.set(jsonOrder)

    // todo: user history
    // await this.refUserOrder.doc(this.userId).set({
    //   createdAt: orderDate,
    //   restoId,
    //   restoName,
    //   postalCode: restoPostal,
    //   orderId: docRef.id,
    //   orderNbr: orderNbr,
    //   total,
    //   orderType: type,
    //   table,
    //   status: 1,
    //   hasReview: false
    // })
    if (redirect)
      this.setState({ showOrderConfirmed: true })
    else return docRef.id;
  }

  handleDaySelect = (e) => {
    if (e.target.value === '0') { // today
      this.setState({ pickupDate: new Date(), hasPickedTime: false })
    }
    else if (e.target.value === '1') {
      this.setState({ pickupDate: addDays(new Date(), 1), hasPickedTime: false });
    }
  }

  handleTimeChange = (date) => {
    this.setState({ pickupDate: date, hasPickedTime: true })
  }

  handleOrderTypeChange = (orderType) => {
    this.props.setDiscountSettings({ takeawayDiscount: { discount: this.props.settings.takeawaySettings.discount, discountFrom: this.props.settings.takeawaySettings.discountFrom }, deliveryDiscount: { discount: this.props.settings.deliverySettings.discount, discountFrom: this.props.settings.deliverySettings.discountFrom } })
    this.props.applyDiscount(orderType === 1 ? this.props.settings.takeawaySettings.discount || 0 : orderType === 4 ? this.props.settings.deliverySettings.discount || 0 : 0);
    this.props.updateType(orderType)
    this.setState({ orderType, pickupDate: this.getAvailableDate(orderType), paymentMethod: null });

    this.canPreOrder = this.state.orderType === 1 ? (this.props.settings.takeawaySettings.preOrder === undefined || this.props.settings.takeawaySettings.preOrder) :
      this.state.orderType === 4 ? (this.props.settings.deliverySettings.preOrder === undefined || this.props.settings.deliverySettings.preOrder) : true
  }

  getAvailableDate = (type = null) => {
    if (type === null) type = this.state.orderType;
    const offset = type === 1 ? (this.props.settings.takeawaySettings.prepTime || 15) :
      type === 4 ? (this.props.settings.deliverySettings.deliveryTime || 15) : 15;

    const now = addMinutes(new Date(), offset);

    const dayOfTheWeek = now.getDay() ? now.getDay() - 1 : 6;
    const schedule = type === 1 ? this.props.settings.orderSchedule :
      type === 4 ? this.props.settings.deliverySchedule : [];

    const todaySchedule = schedule[dayOfTheWeek] || [];
    for (let i = 0; i < todaySchedule.length; ++i) {
      let dateStart = parse(todaySchedule[i].start, 'HH:mm', now);
      const dateEnd = addSeconds(parse(todaySchedule[i].end, 'HH:mm', now), 59);
      while (dateStart < dateEnd) {
        if (now >= dateStart && now <= dateEnd && i === 0) {
          return now
        }
        if (dateStart > now)
          return dateStart

        dateStart = addMinutes(dateStart, 15);
      }
    }

    const tomorrow = addDays(now, 1);
    const tomorrowSchedule = schedule[now.getDay()] || [];

    for (let i = 0; i < tomorrowSchedule.length; ++i) {
      let dateStart = parse(tomorrowSchedule[i].start, 'HH:mm', tomorrow);
      const dateEnd = addSeconds(parse(tomorrowSchedule[i].end, 'HH:mm', tomorrow), 59);

      while (dateStart < dateEnd) {
        if (dateStart > now)
          return dateStart;

        dateStart = addMinutes(dateStart, 15);
      }
    }

    return null;
  }


  getAvailableDateString = () => {
    const availableDate = this.getAvailableDate()
    if (!availableDate) {
      this.dateAvailable = false
      return 'Plus de creneaux aujourd\'hui, ni demain'
    }

    if (!this.canPreOrder) {
      const date1 = dayjs(availableDate)
      const date2 = dayjs()

      const prepTime = this.state.orderType === 1 ? (this.props.settings.takeawaySettings.prepTime || 15) :
        this.state.orderType === 4 ? this.props.settings.deliverySettings.deliveryTime : 15

      if (Math.abs(date1.diff(date2, 'minutes')) > prepTime + 15) {
        this.dateAvailable = false
        return `Vous pouvez passer la commande à partir du: ${dayjs(date1).subtract(prepTime, 'minutes').format('DD MMMM à HH:mm')}`
      }
    }


    this.dateAvailable = true
    return `Dès que possible: ${formatRelative(availableDate, new Date(), { locale: fr })}`
  }

  closeProductModal = () => {
    this.setState({ showProductDetail: false })
  }

  currentDateSelector = () => {
    this.setState({ userAt: 'now', pickupDate: this.getAvailableDate(), hasPickedTime: false })
  }


  otherDateSelector = () => {
    const now = new Date();
    const dayOfTheWeek = now.getDay() ? now.getDay() - 1 : 6;

    if (this.props.settings.orderSchedule[dayOfTheWeek].length > 0) {
      this.setState({ userAt: 'custom', hasPickedTime: false, pickupDate: new Date() })
      return
    }

    if (this.props.settings.orderSchedule[now.getDay()].length > 0) {
      this.setState({ userAt: 'custom', hasPickedTime: false, pickupDate: addDays(new Date(), 1) })
      return
    }
  }

  getOrderTotal = () => {
    let total = this.props.cart.total;
    if (this.props.cart.coupon !== null) {
      if (this.props.cart.coupon?.discountType === 'percent') {
        total = Number.parseFloat(computeDiscount(total, this.props.cart.coupon.discountPercent, true).toFixed(2));
      }
      else if (this.props.cart.coupon?.discountType === 'price') {
        total -= this.props.cart.coupon.discountPrice;
      }
    }
    if (this.state.orderType === 4 && !this.isDeliveryFree()) {
      if (this.state.userPostal !== null)
        total += this.props.settings.deliverySettings.deliveryPostal[this.state.userPostal].postalFee || 0;
    }
    return total;
  }

  isDeliveryFree = () => {
    if (this.state.userPostal !== null) {
      const deliveryPostal = this.props.settings.deliverySettings.deliveryPostal[this.state.userPostal];
      if (!deliveryPostal || !deliveryPostal.postalMinFree)
        return false;
      else
        return computeDiscount(this.props.cart.total, this.props.cart.discount, true) >= (deliveryPostal.postalMinFree || 0);
    }
    return true;
  }

  getMinimumPrice = () => {
    if (this.state.userPostal !== null) {
      return (
        this.state.orderType === 1 ? this.props.settings.takeawaySettings.minimumPrice || 0 :
          this.state.orderType === 4 ? this.props.settings.deliverySettings.deliveryPostal[this.state.userPostal].postalMin || 0 : 0
      );
    }
    return this.state.orderType === 1 ? this.props.settings.takeawaySettings.minimumPrice || 0 : 0;
  }

  totalAboveMinimumPrice = () => {
    return this.props.cart.total >= this.getMinimumPrice();
  }

  setPaymentMethod = (paymentMethod, onlinePayment) => {
    this.setState({
      paymentMethod,
      stripePayment: onlinePayment,
    })
  }

  applyCoupon = async (e) => {
    e.preventDefault();
    if (!this.state.couponCode) {
      return;
    }

    const coupon = await firestore()
      .collection('Restaurant')
      .doc(this.props.cart.restoId)
      .collection('Coupon')
      .doc(this.state.couponCode)
      .get();

    if (coupon.exists) {
      const data = coupon.data();
      data.id = coupon.id;
      data.expirationDate = data.expirationDate && data.expirationDate.toDate();
      if (this.couponCheck(data)) {
        let msg = ''
        if (data.discountType === 'product')
          msg = `${data.discountProduct}`
        else if (data.discountType === 'percent')
          msg = `Une remise de ${data.discountPercent}% a été appliquer sur votre commande`
        else if (data.discountType === 'price')
          msg = `Une remise de ${data.discountPrice}€ a été appliquer sur votre commande`
        Swal.fire({
          icon: 'success',
          title: `${coupon.id}`,
          text: msg
        })
        this.props.applyCoupon(data);
      }
    }
    else {
      Swal.fire('Ce code n\'existe pas.', '', 'error');
    }
  }

  couponCheck = (coupon) => {
    if (!coupon) return true
    if (coupon?.expirationDate && (new Date(coupon?.expirationDate) < new Date())) {
      Swal.fire('Ce code n\'est plus valable.', '', 'error');
      return false;
    }
    else if (this.props.cart.total <= coupon?.minOrder) {
      Swal.fire('Attention', `Ce code est valable à partir de ${coupon?.minOrder.toFixed(2)}€ de commande.`, 'warning');
      return false;
    }
    else if (!((this.props.cart.orderType === 1 && coupon?.takeawayCoupon) || (this.props.cart.orderType === 4 && coupon.deliveryCoupon))) {
      Swal.fire('Attention', `Ce code n'est valable que pour les commandes ${coupon?.takeawayCoupon ? 'à emporter' : ''} ${coupon?.deliveryCoupon ? 'en livraison' : ''}.`, 'warning');
      return false;
    }
    else return true;
  }

  checkMaxDistance = async (event) => {
    if (event && event.preventDefault)
      event.preventDefault()

    console.log(this.state.userAddress, this.state.deliveryPostal[this.state.userPostal])

    const response = await fetch("https://nominatim.openstreetmap.org/search.php?street=25%20av%20leon%20martin&city=le%20blanc%20mesnil&country=france&postalcode=93000&format=jsonv2", {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      method: "POST"
    })

    const json = await response.json()


    console.log(json[0].lat, json[0].lon)
  }

  removeCoupon = () => {
    this.props.applyCoupon(null);
  }

  render() {
    const now = new Date();
    const dayOfTheWeek = now.getDay() ? now.getDay() - 1 : 6;
    const confirmDisable = (this.state.userAt === 'custom' && this.state.hasPickedTime === false)
      || this.props.cart.products.length === 0
      || this.dateAvailable === false
      || !this.totalAboveMinimumPrice()
      || ((this.state.orderType === 1 && this.props.settings.takeawaySettings.localPayment && this.props.settings.takeawaySettings.localPaymentMethods) && this.state.paymentMethod === null)
      || ((this.state.orderType === 4 && this.props.settings.deliverySettings.localPayment && this.props.settings.deliverySettings.localPaymentMethods) && this.state.paymentMethod === null)
      || (((this.state.orderType === 1 && this.props.settings.takeawaySettings.onlinePayment) || (this.state.orderType === 4 && this.props.settings.deliverySettings.onlinePayment)) && this.state.paymentMethod === null)



    if (this.state.showOrderConfirmed)
      return (<Redirect to={`confirm`} />)

    return (
      <Container className="container" id="checkout">
        {
          this.props.settings.color && (
            <style> {`body {
              --website-color: ${this.props.settings.color}
            }`}
            </style>
          )
        }
        <Breakpoint medium up>
          <TopBar match={this.props.match} leftButton backLink={`/${this.props.match.params.id}`} />
          <Form className="form">
            <div className="left-block">

              {this.props.settings.takeaway === true && this.props.settings.delivery === true && (
                <div className="cart-product-container">
                  <div className="cart-title">
                    <span className="step">1</span>Mode de retrait
                  </div>
                  <div className="order-type-pick">
                    {this.props.settings.orderSchedule.length && (this.props.settings.orderSchedule[now.getDay()].length > 0 || this.props.settings.orderSchedule[dayOfTheWeek].length > 0) ? (
                      <div
                        onClick={() => this.handleOrderTypeChange(1)}
                        className={`order-type-choice ${this.state.orderType === 1 ? 'selected' : ''}`}
                      >
                        {getOrderType(1)}
                        {this.state.orderType === 1 && (
                          <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                        )}
                      </div>
                    ) : (
                      <div
                        className={`order-type-choice disabled`}
                      >
                        {getOrderType(1)}
                        {this.state.orderType === 1 && (
                          <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                        )}
                      </div>
                    )}

                    {this.props.settings.deliverySchedule.length && (this.props.settings.deliverySchedule[now.getDay()].length > 0 || this.props.settings.deliverySchedule[dayOfTheWeek].length > 0) ? (
                      <div
                        onClick={() => this.handleOrderTypeChange(4)}
                        className={`order-type-choice ${this.state.orderType === 4 ? 'selected' : ''}`}
                      >
                        {getOrderType(4)}
                        {this.state.orderType === 4 && (
                          <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                        )}
                      </div>
                    ) : (
                      <div
                        className={`order-type-choice disabled`}
                      >
                        {getOrderType(4)}
                        {this.state.orderType === 4 && (
                          <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                        )}
                      </div>
                    )}
                  </div>
                  {this.getMinimumPrice() > 0 && (
                    <div style={{ textAlign: 'center' }}>
                      {
                        this.state.orderType === 1 ? `Minimum de commande à emporter: ${this.getMinimumPrice().toFixed(2)}€` :
                          this.state.orderType === 4 ? `Minimum de commande en livraison: ${this.getMinimumPrice().toFixed(2)}€` :
                            ''
                      }
                    </div>
                  )}
                </div>
              )}

              <hr />
              <div className="cart-my-order">
                <div className="cart-title"><span className="step">2</span>Mes informations</div>
              </div>

              <div className="cart-product-container">

                <Form.Row>
                  <Col>
                    <Form.Control size="lg" type="text" placeholder="Nom *" required value={this.state.userLastName} maxLength="25" onChange={(t) => this.setState({ userLastName: t.target.value })} />
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Control size="lg" type="text" placeholder="Prénom *" required value={this.state.userFirstName} maxLength="25" onChange={(t) => this.setState({ userFirstName: t.target.value })} />
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Control size="lg" type="email" placeholder="Email *" required value={this.state.userEmail} maxLength="50" onChange={(t) => this.setState({ userEmail: t.target.value })} />
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <InputMask mask="+33 09 99 99 99 99" placeholder="Téléphone *" maskChar={'_'} type="tel" className="form-control form-control-lg" onChange={(t) => this.setState({ userPhone: t.target.value })} value={this.state.userPhone} />
                  </Col>
                </Form.Row>
                {this.state.orderType === 4 && (
                  <>
                    <div className="cart-title">Adresse de livraison</div>
                    <Form.Row>
                      <Col>
                        <Form.Control size="lg" type="text" placeholder="Adresse *" required value={this.state.userAddress} onChange={(t) => this.setState({ userAddress: t.target.value })} />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <Form.Control size="lg" as="select" value={this.state.userPostal !== null ? this.state.userPostal : ""} onChange={(t) => this.setState({ userPostal: Number.parseInt(t.target.value) })}>
                          <option value="" disabled>Code postal *</option>
                          {this.state.deliveryPostal.map((p, i) => (
                            <option key={i} value={i}>{p.postalCode} - {p.postalCity} {p.postalFee ? '- Livraison: ' + p.postalFee.toFixed(2) : ''} {p.postalMinFree ? '- Offert à partir de: ' + p.postalMinFree.toFixed(2) : ''} {p.postalMin ? '- Minimum de commande: ' + p.postalMin.toFixed(2) : ''}</option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Col>
                        <Form.Control size="lg" as='textarea' type="text" placeholder="Complément d'adresse (interphone, code portail ...)" value={this.state.userAddressComplement} onChange={(t) => this.setState({ userAddressComplement: t.target.value })} />
                      </Col>
                    </Form.Row>

                    {/* <button onClick={this.checkMaxDistance} >Verifier la distance de mon adresse</button> */}
                  </>
                )}


                <div className="checkbox-form">
                  <Checkbox label="Se souvenir de moi" checked={this.state.rememberMe ?? false} onChange={() => this.setState({ rememberMe: !this.state.rememberMe })} key="abcd" />
                </div>

              </div>

              <hr />
              <div className="cart-my-order">
                <div className="cart-title">
                  <span className="step">3</span>
                  {this.state.orderType === 1 ? 'Heure de retrait' :
                    this.state.orderType === 4 ? 'Heure de livraison' :
                      ''}
                </div>
              </div>

              <div className="cart-product-container">

                <div key={`custom-radio`} className="mb-3">
                  {this.dateAvailable && (
                    <Form.Check
                      name={'ex'}
                      custom
                      type={'radio'}
                      id={`type1`}
                      label={`${this.getAvailableDateString()}`}
                      checked={this.state.userAt === 'now'}
                      onChange={this.currentDateSelector} />
                  )}

                  {!this.dateAvailable && (
                    this.getAvailableDateString()
                  )}

                  {this.canPreOrder && (
                    <Form.Check
                      name={'ex'}
                      custom
                      type={'radio'}
                      id={`type2`}
                      label={`Je choisis mon heure de ${this.state.orderType === 1 ? 'retrait' : this.state.orderType === 4 ? 'livraison' : ''}`}
                      checked={this.state.userAt === 'custom'}
                      onChange={this.otherDateSelector}
                    />
                  )}

                  {this.state.userAt === 'custom' && (
                    <div>
                      {this.state.orderType === 1 && (
                        <Form.Control as="select" onChange={this.handleDaySelect}>
                          {this.props.settings.orderSchedule[dayOfTheWeek].length > 0 && <option value={0}>Aujourd'hui ({format(now, 'iiii dd MMMM', { locale: fr })})</option>}
                          {this.props.settings.orderSchedule[now.getDay()].length > 0 && <option value={1}>Demain ({format(addDays(now, 1), 'iiii dd MMMM', { locale: fr })})</option>}
                        </Form.Control>
                      )}

                      {this.state.orderType === 4 && (
                        <Form.Control as="select" onChange={this.handleDaySelect}>
                          {this.props.settings.deliverySchedule[dayOfTheWeek].length > 0 && <option value={0}>Aujourd'hui ({format(now, 'iiii dd MMMM', { locale: fr })})</option>}
                          {this.props.settings.deliverySchedule[now.getDay()].length > 0 && <option value={1}>Demain ({format(addDays(now, 1), 'iiii dd MMMM', { locale: fr })})</option>}
                        </Form.Control>
                      )}

                      <TimePicker
                        offset={
                          this.state.orderType === 1 ? (this.props.settings.takeawaySettings.prepTime || 15) :
                            this.state.orderType === 4 ? this.props.settings.deliverySettings.deliveryTime : 15
                        }
                        selected={this.state.pickupDate}
                        creneaux={
                          this.state.orderType === 1 ? this.props.settings.orderSchedule :
                            this.state.orderType === 4 ? this.props.settings.deliverySchedule : []
                        }
                        onChange={this.handleTimeChange}
                      />
                    </div>
                  )}

                </div>
              </div>

              {this.state.orderType === 1 && this.props.settings.takeawaySettings.localPayment && this.props.settings.takeawaySettings.localPaymentMethods && (
                <>
                  <hr />
                  <div className="cart-my-order">
                    <div className="cart-title">
                      <span className="step">4</span>
                      Paiement sur place
                    </div>
                  </div>
                  <div className="cart-product-container">
                    <div className='payment-option-container'>
                      {
                        new Array(7).fill('').map((o, i) => this.props.settings.takeawaySettings.localPaymentMethods.includes(i + 1) && (
                          <div
                            key={i}
                            className={`payment-option-button ${this.state.paymentMethod === i + 1 ? 'selected' : ''}`}
                            onClick={() => this.setPaymentMethod(i + 1, false)}
                          >
                            {this.state.paymentMethod === i + 1 && (
                              <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                            )}
                            <span>
                              {paymentIdToName(i + 1)}
                            </span>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </>
              )}


              {this.state.orderType === 4 && this.props.settings.deliverySettings.localPayment && this.props.settings.deliverySettings.localPaymentMethods && (
                <>
                  <div className="cart-my-order">
                    <div className="cart-title">
                      Paiement au livreur
                    </div>
                  </div>
                  <div className="cart-product-container">
                    <div className='payment-option-container'>
                      {
                        new Array(7).fill('').map((o, i) => this.props.settings.deliverySettings.localPaymentMethods.includes(i + 1) && (
                          <div
                            key={i}
                            className={`payment-option-button ${this.state.paymentMethod === i + 1 ? 'selected' : ''}`}
                            onClick={() => this.setPaymentMethod(i + 1, false)}
                          >
                            {this.state.paymentMethod === i + 1 && (
                              <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                            )}
                            <span>
                              {paymentIdToName(i + 1)}
                            </span>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </>
              )}

              {((this.state.orderType === 1 && this.props.settings.takeawaySettings.onlinePayment) || (this.state.orderType === 4 && this.props.settings.deliverySettings.onlinePayment)) && (
                <>
                  <div className="cart-my-order">
                    <div className="cart-title">
                      Paiement en ligne
                    </div>
                  </div>
                  <div className="cart-product-container">
                    <div className='payment-option-container'>
                      <div
                        className={`payment-option-button ${this.state.stripePayment ? 'selected' : ''}`}
                        onClick={() => this.setPaymentMethod(8, true)}
                      >
                        {this.state.stripePayment && (
                          <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                        )}
                        <span>
                          Carte Bancaire
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <hr />
              <div className="cart-my-order">
                <div className="cart-title">
                  <span className="step">5</span>
                  Code promo
                </div>
              </div>
              <div className="cart-product-container">
                <div className="form" onSubmit={this.applyCoupon}>
                  <Form.Row>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder="Code promo"
                        value={this.state.couponCode}
                        onChange={(t) => this.setState({ couponCode: t.target.value })}
                      />
                    </Col>
                    <Col>
                      <div className='coupon-button' onClick={this.applyCoupon}>
                        Appliquer
                      </div>
                    </Col>
                  </Form.Row>
                </div>
              </div>

              <hr />
              <div className="cart-my-order">
                <div className="cart-title">
                  <span className="step">6</span>
                  Commentaire
                </div>
              </div>

              <Form.Group controlId="exampleForm.ControlTextarea1" className="comment-block">
                <Form.Control
                  as="textarea"
                  rows="3"
                  type="text"
                  placeholder="Ajouter un commentaire (facultatif)"
                  value={this.state.userComment}
                  maxLength="120"
                  onChange={(t) => this.setState({ userComment: t.target.value })}
                />
              </Form.Group>

            </div>


            <div className="cart">
              <div className="cart-my-order">
                <div className="cart-title">Ma commande</div>
              </div>
              <div className="cart-product-container">
                {this.props.cart.products.map((product, index) =>
                  <ProductCartRow product={product} key={index} index={index} onClick={this.onClickProduct} />
                )}
              </div>

              <div className="cart-option-container">
                {this.state.cartOptions === null && (

                  <ContentLoader
                    viewBox="0 0 400 160"
                    height={160}
                    width={400}
                    backgroundColor="transparent"

                  >
                    <circle cx="150" cy="86" r="8" />
                    <circle cx="194" cy="86" r="8" />
                    <circle cx="238" cy="86" r="8" />
                  </ContentLoader>

                )}

                {this.state.cartOptions !== null && (
                  <div className="cart-option">
                    {this.state.cartOptions.map((option, idx) => (
                      <div className="checkbox-form">
                        <Checkbox label={option.name} checked={option.checked ?? false} onChange={() => { option.checked = !option.checked; this.forceUpdate() }} key={idx} />
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {this.props.cart.discount > 0 && this.props.cart.subtotal !== this.props.cart.total && (
                <div className="cart-total-container">
                  <div className="cart-row" style={{ width: '100%', justifyContent: 'space-between' }}>
                    <div>Sous-total</div>
                    <div>{(this.props.cart.subtotal).toFixed(2)} €</div>
                  </div>
                </div>
              )}

              {this.props.cart.discount > 0 && this.props.cart.subtotal !== this.props.cart.total && (
                <div className="cart-total-container">
                  <div className="cart-row" style={{ width: '100%', justifyContent: 'space-between' }}>
                    <div>Remise {this.state.orderType === 1 ? 'emporté' : this.state.orderType === 4 ? 'livraison' : ''} {this.props.cart.discount}%</div>
                    <div>-{(this.props.cart.subtotal - this.props.cart.total).toFixed(2)} €</div>
                  </div>
                </div>
              )}

              {this.props.cart.coupon !== null ? this.props.cart.coupon?.discountType === 'percent' ? (
                <div className="cart-total-container">
                  <div className={`cart-row`} style={{ width: '100%', justifyContent: 'space-between' }}>
                    <div>
                      <span className="remove-coupon-button" onClick={this.removeCoupon}>X</span>
                      Code Promo {this.props.cart.coupon.discountPercent}%
                    </div>
                    <div>-{computeDiscount(this.props.cart.total, this.props.cart.coupon.discountPercent, false).toFixed(2)}€</div>
                  </div>
                </div>
              ) : this.props.cart.coupon?.discountType === 'price' ? (
                <div className="cart-total-container">
                  <div className={`cart-row`} style={{ width: '100%', justifyContent: 'space-between' }}>
                    <div>
                      <span className="remove-coupon-button" onClick={this.removeCoupon}>X</span>
                      Code Promo {this.props.cart.coupon.discountPrice}€
                    </div>
                    <div>-{this.props.cart.coupon.discountPrice.toFixed(2)}€</div>
                  </div>
                </div>
              ) : this.props.cart.coupon?.discountType === 'product' ? (
                <div className="cart-total-container">
                  <div className={`cart-row`} style={{ width: '100%', justifyContent: 'space-between' }}>
                    <div>
                      <span className="remove-coupon-button" onClick={this.removeCoupon}>X</span>
                      Code Promo {this.props.cart.coupon.discountProduct}
                    </div>
                    <div></div>
                  </div>
                </div>
              ) : null : null}

              {this.state.orderType === 4 && this.state.userPostal !== null && this.props.settings.deliverySettings.deliveryPostal[this.state.userPostal].postalFee && (
                <div className="cart-total-container">
                  <div className={`cart-row ${this.isDeliveryFree() ? 'crossed' : ''}`} style={{ width: '100%', justifyContent: 'space-between' }}>
                    <div>Frais de livraison</div>
                    <div>{this.props.settings.deliverySettings.deliveryPostal[this.state.userPostal].postalFee.toFixed(2)}€</div>
                  </div>
                </div>
              )}

              <div className="cart-total-container">
                <div className="cart-row" style={{ width: '100%', justifyContent: 'space-between' }}>
                  <div className="cart-title">TOTAL</div>
                  <div className="cart-title">
                    {this.getOrderTotal().toFixed(2)} €
                  </div>
                </div>
              </div>

              <div className="footer">
                {!this.state.stripePayment ? (
                  <button
                    type="submit"
                    id="recaptcha-container"
                    onClick={this._submitForm}
                    disabled={confirmDisable}
                  >
                    <div className={`confirm-btn ${confirmDisable ? ' disabled' : ''}`}>Confirmez ma commande</div>
                  </button>
                ) : (
                  <StripePaymentButton
                    confirmCart={this._submitForm}
                    cartTotal={this.getOrderTotal()}
                    urlName={this.props.match.params.id}
                    disabled={confirmDisable}
                    stripePubkey={this.props.settings.stripe.pubkey}
                    stripeAccountId={this.props.settings.stripe.id}
                  />
                )}
              </div>
            </div>
          </Form>
        </Breakpoint>

        <Breakpoint small down>
          <TopBar match={this.props.match} leftButton backLink={`/${this.props.match.params.id}`} />

          <div className="cart-my-order">
            <div className="cart-title">Ma commande</div>
          </div>
          <div className="cart-product-container">
            {this.props.cart.products.map((product, index) =>
              <ProductCartRow product={product} key={index} index={index} onClick={this.onClickProduct} />
            )}
          </div>

          <div className="cart-option-container">
            {this.state.cartOptions === null && (

              <ContentLoader
                viewBox="0 0 400 160"
                height={160}
                width={400}
                backgroundColor="transparent"

              >
                <circle cx="150" cy="86" r="8" />
                <circle cx="194" cy="86" r="8" />
                <circle cx="238" cy="86" r="8" />
              </ContentLoader>

            )}

            {this.state.cartOptions !== null && (
              <div className="cart-option">
                {this.state.cartOptions.map((option, idx) => (
                  <div className="checkbox-form">
                    <Checkbox label={option.name} checked={option.checked ?? false} onChange={() => { option.checked = !option.checked; this.forceUpdate() }} key={idx} />
                  </div>
                ))}
              </div>
            )}
          </div>

          {this.props.cart.discount > 0 && this.props.cart.subtotal !== this.props.cart.total && (
            <div className="cart-total-container">
              <div className="cart-row" style={{ width: '100%', justifyContent: 'space-between' }}>
                <div>Remise {this.state.orderType === 1 ? 'emporté' : this.state.orderType === 4 ? 'livraison' : ''} {this.props.cart.discount}%</div>
                <div>-{(this.props.cart.subtotal - this.props.cart.total).toFixed(2)} €</div>
              </div>
            </div>
          )}

          {this.props.cart.coupon !== null ? this.props.cart.coupon?.discountType === 'percent' ? (
            <div className="cart-total-container">
              <div className={`cart-row`} style={{ width: '100%', justifyContent: 'space-between' }}>
                <div>
                  <span className="remove-coupon-button" onClick={this.removeCoupon}>X</span>
                  Code Promo {this.props.cart.coupon.discountPercent}%
                </div>
                <div>-{computeDiscount(this.props.cart.total, this.props.cart.coupon.discountPercent, false).toFixed(2)}€</div>
              </div>
            </div>
          ) : this.props.cart.coupon?.discountType === 'price' ? (
            <div className="cart-total-container">
              <div className={`cart-row`} style={{ width: '100%', justifyContent: 'space-between' }}>
                <div>
                  <span className="remove-coupon-button" onClick={this.removeCoupon}>X</span>
                  Code Promo {this.props.cart.coupon.discountPrice}€
                </div>
                <div>-{(this.props.cart.total - this.props.cart.coupon.discountPrice).toFixed(2)}€</div>
              </div>
            </div>
          ) : this.props.cart.coupon?.discountType === 'product' ? (
            <div className="cart-total-container">
              <div className={`cart-row`} style={{ width: '100%', justifyContent: 'space-between' }}>
                <div>
                  <span className="remove-coupon-button" onClick={this.removeCoupon}>X</span>
                  Code Promo {this.props.cart.coupon.discountProduct}
                </div>
                <div></div>
              </div>
            </div>
          ) : null : null}

          {this.state.orderType === 4 && this.state.userPostal !== null && this.props.settings.deliverySettings.deliveryPostal[this.state.userPostal].postalFee && (
            <div className="cart-total-container">
              <div className={`cart-row ${this.isDeliveryFree() ? 'crossed' : ''}`} style={{ width: '100%', justifyContent: 'space-between' }}>
                <div>Frais de livraison</div>
                <div>{this.props.settings.deliverySettings.deliveryPostal[this.state.userPostal].postalFee.toFixed(2)}€</div>
              </div>
            </div>
          )}

          <div className="cart-total-container">
            <div className="cart-row" style={{ width: '100%', justifyContent: 'space-between' }}>
              <div className="cart-title">TOTAL</div>
              <div className="cart-title">{this.getOrderTotal().toFixed(2)} €</div>
            </div>
          </div>

          <hr />
          {this.props.settings.takeaway === true && this.props.settings.delivery === true && (

            <div className="cart-product-container">
              <div className="cart-title">
                <span className="step">1</span>Mode de retrait
              </div>
              <div className="order-type-pick">
                {this.props.settings.orderSchedule.length && (this.props.settings.orderSchedule[now.getDay()].length > 0 || this.props.settings.orderSchedule[dayOfTheWeek].length > 0) ? (
                  <div
                    onClick={() => this.handleOrderTypeChange(1)}
                    className={`order-type-choice ${this.state.orderType === 1 ? 'selected' : ''}`}
                  >
                    {getOrderType(1)}
                    {this.state.orderType === 1 && (
                      <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                    )}
                  </div>
                ) : (
                  <div className={`order-type-choice disabled`}>
                    {getOrderType(1)}
                    {this.state.orderType === 1 && (
                      <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                    )}
                  </div>
                )}


                {this.props.settings.deliverySchedule.length && (this.props.settings.deliverySchedule[now.getDay()].length > 0 || this.props.settings.deliverySchedule[dayOfTheWeek].length > 0) ? (

                  <div
                    onClick={() => this.handleOrderTypeChange(4)}
                    className={`order-type-choice ${this.state.orderType === 4 ? 'selected' : ''}`}
                  >
                    {getOrderType(4)}
                    {this.state.orderType === 4 && (
                      <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                    )}
                  </div>
                ) : (
                  <div className={`order-type-choice disabled`}>
                    {getOrderType(4)}
                    {this.state.orderType === 4 && (
                      <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                    )}
                  </div>
                )}
              </div>
              {this.getMinimumPrice() > 0 && (
                <div style={{ textAlign: 'center' }}>
                  {
                    this.state.orderType === 1 ? `Minimum de commande à emporter: ${this.getMinimumPrice().toFixed(2)}€` :
                      this.state.orderType === 4 ? `Minimum de commande en livraison: ${this.getMinimumPrice().toFixed(2)}€` :
                        ''
                  }
                </div>
              )}
            </div>
          )}


          <Form autoComplete="off">
            <hr />
            <div className="cart-my-order">
              <div className="cart-title">
                <span className="step">2</span>Mes informations
              </div>
            </div>

            <div className="cart-product-container">

              <Form.Row>
                <Col>
                  <Form.Control size="lg" type="text" placeholder="Nom *" required value={this.state.userLastName} onChange={(t) => this.setState({ userLastName: t.target.value })} />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Control size="lg" type="text" placeholder="Prénom *" required value={this.state.userFirstName} onChange={(t) => this.setState({ userFirstName: t.target.value })} />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Control size="lg" type="email" placeholder="Email *" required value={this.state.userEmail} onChange={(t) => this.setState({ userEmail: t.target.value })} />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  {/* <NumberFormat value={this.state.userPhone} prefix={'$'}format="+33 0# ## ## ## ##" type='tel' mask="_" allowEmptyFormatting className="form-control form-control-lg" onValueChange={(value) => this.setState({ userPhone: value.formattedValue })} /> */}
                  <InputMask mask="+33 09 99 99 99 99" placeholder="Téléphone *" maskChar={'_'} type="tel" className="form-control form-control-lg" onChange={(t) => this.setState({ userPhone: t.target.value })} value={this.state.userPhone} />
                </Col>
              </Form.Row>
              {this.state.orderType === 4 && (<>
                <Form.Row>
                  <Col>
                    <Form.Control size="lg" type="text" placeholder="Addresse *" required value={this.state.userAddress} onChange={(t) => this.setState({ userAddress: t.target.value })} />
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Control size="lg" as="select" value={this.state.userPostal !== null ? this.state.userPostal : ""} onChange={(t) => this.setState({ userPostal: Number.parseInt(t.target.value) })}>
                      <option value="" disabled selected>Code postal *</option>
                      {this.state.deliveryPostal.map((p, i) => (
                        <option key={i} value={i}>{p.postalCode} - {p.postalCity} {p.postalFee ? '- Livraison: ' + p.postalFee.toFixed(2) : ''} {p.postalMinFree ? '- Offert à partir de: ' + p.postalMinFree.toFixed(2) : ''} {p.postalMin ? '- Minimum de commande: ' + p.postalMin.toFixed(2) : ''}</option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Control size="lg" as='textarea' type="text" placeholder="Complément d'adresse (batiment, interphone, code portail ...)" value={this.state.userAddressComplement} onChange={(t) => this.setState({ userAddressComplement: t.target.value })} />
                  </Col>
                </Form.Row>
              </>)}
              <div className="checkbox-form">
                <Checkbox label="Se souvenir de moi" checked={this.state.rememberMe ?? false} onChange={() => this.setState({ rememberMe: !this.state.rememberMe })} key="abc" />
              </div>
            </div>

            <hr />
            <div className="cart-my-order">
              <div className="cart-title">
                <span className="step">3</span>
                {this.state.orderType === 1 ? 'Heure de retrait' :
                  this.state.orderType === 4 ? 'Heure de livraison' :
                    ''}
              </div>
            </div>

            <div className="cart-product-container">

              <div key={`custom-radio`} className="mb-3">
                {this.dateAvailable && (
                  <Form.Check
                    name={'ex'}
                    custom
                    type={'radio'}
                    id={`type1`}
                    label={`${this.getAvailableDateString()}`}
                    checked={this.state.userAt === 'now'}
                    onChange={this.currentDateSelector}
                  />
                )}

                {!this.dateAvailable && (
                  this.getAvailableDateString()
                )}

                {this.canPreOrder && (
                  <Form.Check
                    name={'ex'}
                    custom
                    type={'radio'}
                    id={`type2`}
                    label={`Je choisis mon heure de ${this.state.orderType === 1 ? 'retrait' : this.state.orderType === 4 ? 'livraison' : ''}`}
                    checked={this.state.userAt === 'custom'}
                    onChange={this.otherDateSelector}
                  />
                )}

                {this.state.userAt === 'custom' && (
                  <div>
                    {this.state.orderType === 1 && (
                      <Form.Control as="select" onChange={this.handleDaySelect}>
                        {this.props.settings.orderSchedule[dayOfTheWeek].length > 0 && <option value={0}>Aujourd'hui ({format(now, 'iiii dd MMMM', { locale: fr })})</option>}
                        {this.props.settings.orderSchedule[now.getDay()].length > 0 && <option value={1}>Demain ({format(addDays(now, 1), 'iiii dd MMMM', { locale: fr })})</option>}
                      </Form.Control>
                    )}

                    {this.state.orderType === 4 && (
                      <Form.Control as="select" onChange={this.handleDaySelect}>
                        {this.props.settings.deliverySchedule[dayOfTheWeek].length > 0 && <option value={0}>Aujourd'hui ({format(now, 'iiii dd MMMM', { locale: fr })})</option>}
                        {this.props.settings.deliverySchedule[now.getDay()].length > 0 && <option value={1}>Demain ({format(addDays(now, 1), 'iiii dd MMMM', { locale: fr })})</option>}
                      </Form.Control>
                    )}

                    <TimePicker
                      offset={
                        this.state.orderType === 1 ? (this.props.settings.takeawaySettings.prepTime || 15) :
                          this.state.orderType === 4 ? this.props.settings.deliverySettings.deliveryTime : 15
                      }
                      selected={this.state.pickupDate}
                      creneaux={
                        this.state.orderType === 1 ? this.props.settings.orderSchedule :
                          this.state.orderType === 4 ? this.props.settings.deliverySchedule : []
                      }
                      onChange={this.handleTimeChange}
                    />
                  </div>
                )}

              </div>
            </div>

            {this.state.orderType === 1 && this.props.settings.takeawaySettings.localPayment && this.props.settings.takeawaySettings.localPaymentMethods && (
              <>
                <hr />
                <div className="cart-my-order">
                  <div className="cart-title">
                    <span className="step">4</span>
                    Paiement sur place
                  </div>
                </div>
                <div className="cart-product-container">
                  <div className='payment-option-container'>
                    {
                      new Array(7).fill('').map((o, i) => this.props.settings.takeawaySettings.localPaymentMethods.includes(i + 1) && (
                        <div
                          key={i}
                          className={`payment-option-button ${this.state.paymentMethod === i + 1 ? 'selected' : ''}`}
                          onClick={() => this.setPaymentMethod(i + 1, false)}
                        >
                          {this.state.paymentMethod === i + 1 && (
                            <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                          )}
                          <span>
                            {paymentIdToName(i + 1)}
                          </span>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </>
            )}


            {this.state.orderType === 4 && this.props.settings.deliverySettings.localPayment && this.props.settings.deliverySettings.localPaymentMethods && (
              <>
                <div className="cart-my-order">
                  <div className="cart-title">
                    Paiement au livreur
                  </div>
                </div>
                <div className="cart-product-container">
                  <div className='payment-option-container'>
                    {
                      new Array(7).fill('').map((o, i) => this.props.settings.deliverySettings.localPaymentMethods.includes(i + 1) && (
                        <div
                          key={i}
                          className={`payment-option-button ${this.state.paymentMethod === i + 1 ? 'selected' : ''}`}
                          onClick={() => this.setPaymentMethod(i + 1, false)}
                        >
                          {this.state.paymentMethod === i + 1 && (
                            <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                          )}
                          <span>
                            {paymentIdToName(i + 1)}
                          </span>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </>
            )}

            {((this.state.orderType === 1 && this.props.settings.takeawaySettings.onlinePayment) || (this.state.orderType === 4 && this.props.settings.deliverySettings.onlinePayment)) && (
              <>
                <div className="cart-my-order">
                  <div className="cart-title">
                    Paiement en ligne
                  </div>
                </div>
                <div className="cart-product-container">
                  <div className='payment-option-container'>
                    <div
                      className={`payment-option-button ${this.state.stripePayment ? 'selected' : ''}`}
                      onClick={() => this.setPaymentMethod(8, true)}
                    >
                      {this.state.stripePayment && (
                        <FaCheckCircle size="1.5em" style={{ position: 'absolute', top: '-8px', right: '-4px' }} className="main-color" />
                      )}
                      <span>
                        Carte Bancaire
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}

            <hr />
            <div className="cart-my-order">
              <div className="cart-title">
                <span className="step">5</span>
                Code promo
              </div>
            </div>
            <div className="cart-product-container">
              <Form className="form" onSubmit={this.applyCoupon}>
                <Form.Row>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="Code promo"
                      value={this.state.couponCode}
                      onChange={(t) => this.setState({ couponCode: t.target.value })}
                    />
                  </Col>
                  <Col>
                    <div className='coupon-button' onClick={this.applyCoupon}>
                      Appliquer
                    </div>
                  </Col>
                </Form.Row>
              </Form>
            </div>

            <hr />
            <div className="cart-my-order">
              <div className="cart-title">
                <span className="step">6</span>
                Commentaire
              </div>
            </div>


            <Form.Group controlId="exampleForm.ControlTextarea1" className="comment-block">
              <Form.Control
                as="textarea"
                rows="3"
                type="text"
                placeholder="Ajouter un commentaire (facultatif)"
                value={this.state.userComment}
                onChange={(t) => this.setState({ userComment: t.target.value })}
              />
            </Form.Group>


            <div className="footer">
              {!this.state.stripePayment ? (
                <button
                  type="submit"
                  id="recaptcha-container"
                  onClick={this._submitForm}
                  disabled={confirmDisable}
                >
                  <div className={`confirm-btn ${confirmDisable ? ' disabled' : ''}`}>Confirmez ma commande</div>
                </button>
              ) : (
                <StripePaymentButton
                  confirmCart={this._submitForm}
                  cartTotal={this.getOrderTotal()}
                  urlName={this.props.match.params.id}
                  disabled={confirmDisable}
                  stripePubkey={this.props.settings.stripe.pubkey}
                  stripeAccountId={this.props.settings.stripe.id}
                />
              )}
            </div>


          </Form>

        </Breakpoint>

        <FooterBar orderType={this.state.orderType} />

        <Modal
          show={this.state.showProductDetail}
          className="custom-map-modal"
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          onHide={this.closeProductModal}
        >
          {this.props.cart.products.length > 0 && (
            <ProductDetail
              closeModal={this.toggleModal}
              cart={this.props.cart}
              product={this.props.cart.products[this.state.selectedIdx]}
              updateIndex={this.state.selectedIdx}
            />
          )}
        </Modal>

        <Modal show={this.state.showCodeVerif} onHide={() => this.setState({ showCodeVerif: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Verification du code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>Entrez le code à 6 chiffres recu par sms</label>
            <InputMask mask="999999" placeholder="Code" type="tel" maskChar={null} className="form-control form-control-lg" onChange={(t) => this.setState({ codeInput: t.target.value })} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" size="sm" block onClick={this._phoneVerification}>
              Vous n'avez pas reçu le code ? Renvoyer
            </Button>
            <br />
            <Button variant="primary" size="lg" block onClick={this.validatePhoneCode}>
              Valider le code
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.loading}
          onHide={() => null}
          size="lg"
          centered
          className="custom-cart-modal"
        >
          <Lottie
            options={defaultOptions}
          />
        </Modal>

      </Container >
    )
  }
}

//redux

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    settings: state.settings,
    customerDetails: state.customerDetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateComment: (comment) => dispatch(updateComment(comment)),
    updateType: (type) => dispatch(updateType(type)),
    updateRestoSettings: (a, b, c, d, e, f, g, h) => dispatch(updateRestoSettings(a, b, c, d, e, f, g, h)),
    resetCart: () => dispatch(resetCart()),
    updateRestoInfo: (a, b, c, d, e) => dispatch(updateRestoInfo(a, b, c, d, e)),
    setCustomerDetails: (customerDetails) => dispatch(setCustomerDetails(customerDetails)),
    updateOrderSchedule: (schedule) => dispatch(updateOrderSchedule(schedule)),
    updateDeliverySchedule: (schedule) => dispatch(updateDeliverySchedule(schedule)),
    updateDeliverySettings: (s) => dispatch(updateDeliverySettings(s)),
    updateTakeawaySettings: (s) => dispatch(updateTakeawaySettings(s)),
    updateCartPickupDate: (t) => dispatch(updateCartPickupDate(t)),
    applyDiscount: (d) => dispatch(applyDiscount(d)),
    setCartTotalConfirm: (total) => dispatch(setCartTotalConfirm(total)),
    updateCartFees: (discount, fees) => dispatch(updateCartFees(discount, fees)),
    setFlushCart: () => dispatch(setFlushCart()),
    applyCoupon: (coupon) => dispatch(applyCoupon(coupon)),
    setDiscountSettings: (settings) => dispatch(setDiscountSettings(settings))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
