
// @flow
import React from 'react'
import '../styles/FooterBar.scss'

export default function FooterBar(props) {
  if (props.orderType === 2)
    return (
      <div className="footer-bar">
        Borne de commande by<a href={`https://we-eats.com`}>WE EATS</a>
      </div>
    )
  else
    return (
      <div className="footer-bar">
        Click & Collect by<a href={`https://we-eats.com`}>WE EATS</a>
      </div>
    )
}