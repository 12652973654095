export const UPDATE_SETTINGS = "UPDATE_SETTINGS"
export const UPDATE_DELIVERY_SCHEDULE = "UPDATE_DELIVERY_SCHEDULE"
export const UPDATE_ORDER_SCHEDULE = "UPDATE_ORDER_SCHEDULE"
export const UPDATE_DELIVERY_SETTINGS = "UPDATE_DELIVERY_SETTINGS"
export const UPDATE_TAKEAWAY_SETTINGS = "UPDATE_TAKEAWAY_SETTINGS"

export function updateRestoSettings(id, color, autoAcceptOrder, delivery, takeaway, table, stripe, orderTypePopup) {
  return {
    type: UPDATE_SETTINGS,
    payload: {
      id,
      color,
      autoAcceptOrder,
      delivery,
      takeaway,
      table,
      stripe,
      orderTypePopup
    }
  }
}

export function updateDeliverySchedule(deliverySchedule) {
  return {
    type: UPDATE_DELIVERY_SCHEDULE,
    payload: {
      deliverySchedule
    }
  }
}

export function updateOrderSchedule(orderSchedule) {
  return {
    type: UPDATE_ORDER_SCHEDULE,
    payload: {
      orderSchedule
    }
  }
}

export function updateDeliverySettings(deliverySettings) {
  return {
    type: UPDATE_DELIVERY_SETTINGS,
    payload: {
      deliverySettings
    }
  }
}

export function updateTakeawaySettings(takeawaySettings) {
  return {
    type: UPDATE_TAKEAWAY_SETTINGS,
    payload: {
      takeawaySettings
    }
  }
}
