/**
 * @format
 * @flow
 */

import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { connect } from 'react-redux'
import { setFlushCart } from '../actions/cartAction'
import formatRelative from 'date-fns/formatRelative';
import { fr } from 'date-fns/locale'
import { computeDiscount } from './Utils';
import FooterBar from './FooterBar'

import ProductCartRow from './ProductCartRow'

import '../styles/Confirm.scss';

type Props = {
  cart: object,
  customerDetails: object,
  setFlushCart: Function,
}

type State = {
  showDetails: boolean,
}

class Confirm extends Component<Props, State> {
  constructor(props) {
    super(props);
    props.setFlushCart();

    this.state = {
      showDetails: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const pickupDate = new Date(this.props.cart.pickupDate);
    return (
      <Container className="container" id="confirm-page">
        <h1>Merci pour votre commande, {this.props.customerDetails.firstName} !</h1>

        <p>
          Merci! Votre commande a été prise en compte.<br />
          Vous recevrez un email de confirmation dans quelques minutes.
        </p>

        <div className="confirm-recap">
          <div className="confirm-recap-header"><h4>Récapitulatif de la commande</h4></div>
          <div className={`confirm-recap-toggle ${this.state.showDetails ? 'expanded' : ''}`} onClick={() => this.setState(old => ({ showDetails: !old.showDetails }))}>
            <div>Voir la description</div>
            <div className="confirm-recap-price">
              {!this.state.showDetails &&
                `${this.props.cart.totalConfirm.toFixed(2)} €`
              }
            </div>
          </div>
          <div className={`cart-product-container ${this.state.showDetails ? '' : 'hide'}`}>
            {this.props.cart.products.map((product, index) =>
              <ProductCartRow product={product} key={index} index={index} onClick={() => null} editable={false} />
            )}
            <hr />
            <div>
              {this.props.cart.totalConfirm.toFixed(2) !== this.props.cart.subtotal.toFixed(2) && (
                <div className="cart-product-total">
                  <span>Sous total produit</span>
                  <span>{this.props.cart.subtotal.toFixed(2)} €</span>
                </div>
              )}
              {this.props.cart.discount > 0 && this.props.cart.subtotal !== this.props.cart.total && (
                <div className="cart-product-total">
                  <span>Remise {this.props.cart.type === 1 ? 'emporté' : this.props.cart.type === 4 ? 'livraison' : ''} {this.props.cart.discount}%</span>
                  <span>-{(this.props.cart.subtotal - this.props.cart.total).toFixed(2)} €</span>
                </div>
              )}
              {this.props.cart.coupon && this.props.cart.coupon?.discountType === 'percent' && (
                <div className="cart-product-total">
                  <div>Code Promo {this.props.cart.coupon.discountPercent}%</div>
                  <div>-{computeDiscount(this.props.cart.total, this.props.cart.coupon.discountPercent, false).toFixed(2)} €</div>
                </div>
              )}

              {this.props.cart.coupon && this.props.cart.coupon?.discountType === 'price' && (
                <div className="cart-product-total">
                  <div>Code Promo {this.props.cart.coupon.discountPrice} €</div>
                  <div>-{this.props.cart.coupon.discountPrice.toFixed(2)} €</div>
                </div>
              )}

              {this.props.cart.coupon && this.props.cart.coupon?.discountType === 'product' && (
                <div className="cart-product-total">
                  <div>Code Promo {this.props.cart.coupon.discountProduct}</div>
                </div>
              )}

              {this.props.cart.feeTotal > 0 &&
                <div className="cart-product-total">
                  <span>Frais de livraison</span>
                  <span>{this.props.cart.feeTotal.toFixed(2)} €</span>
                </div>
              }
              <div className="cart-product-total big">
                <span>Total</span>
                <span>{this.props.cart.totalConfirm.toFixed(2)} €</span>
              </div>
            </div>
          </div>
        </div>

        {this.props.cart.orderType === 1 ? (
          <div className="confirm-address">
            <div><h4>Adresse où récupérer votre commande</h4></div>
            <div>
              {this.props.cart.restoInformations.name}
            </div>
            <div>
              {this.props.cart.restoInformations.address}
            </div>
            <div>{this.props.cart.restoInformations.city} - {this.props.cart.restoInformations.postalCode}</div>
          </div>
        ) : this.props.cart.orderType === 4 ? (
          <div className="confirm-address">
            <div><h4>Adresse de livraison</h4></div>
            <div>
              {this.props.customerDetails.address}
            </div>
            <div>
              {this.props.customerDetails.postal.postalCity} {this.props.customerDetails.postal.postalCode}
            </div>
            <div>
              {this.props.customerDetails.addressComplement}
            </div>
          </div>
        ) : ''
        }

        <div className="confirm-address">
          {this.props.cart.orderType === 1 ? (
            <div><h4>Heure de retrait</h4></div>
          ) : this.props.cart.orderType === 4 ? (
            <div><h4>Heure de livraison</h4></div>
          ) : ''}
          <div className="capitalize">
            {formatRelative(pickupDate, new Date(), { locale: fr })}
          </div>
        </div>

        <div className="confirm-email">La confirmation de commande a été envoyée à {this.props.customerDetails.email}</div>

        <div className="confirm-recap">
          <div id="question-recap-title"><h4>Vous avez une question à propos de votre commande?</h4></div>
          <div>Appelez votre restaurant au {this.props.cart.restoInformations.phone}</div>
        </div>


        <div className="back-to-home">
          <Link
            to={`/${this.props.match.params.id}`}
          >
            <div style={{ textAlign: 'center', fontSize: '16px' }}>
              Retour à l'accueil
          </div>
          </Link>
        </div>

        <FooterBar orderType={this.state.orderType} />
      </Container >
    )
  }
}


const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    customerDetails: state.customerDetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFlushCart: () => dispatch(setFlushCart())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);