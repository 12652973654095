export const UPDATE_COLORS = "UPDATE_COLORS"

export function updateColors(useDarkTheme) {
  return {
    type: UPDATE_COLORS,
    payload: {
      useDarkTheme
    }
  }
}
