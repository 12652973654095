import '../styles/Checkbox.scss'

const Checkbox = ({ label, checked, ...props }) => {
  return (
    <div className="checkbox-wrapper">
      <label className="label">
        <span>{label}</span>
        <input
          type="checkbox"
          checked={checked}
          className={checked ? "checked" : ""}
          {...props}
        />
      </label>
    </div>
  );
};
export default Checkbox;