/**
 * @format
 * @flow
 */

import React, { Component } from 'react'
import { Breakpoint } from 'react-socks'
import { Container, Form, Modal } from 'react-bootstrap'
import { Redirect } from "react-router-dom"
import { connect } from 'react-redux'
import { updateComment, updateType, resetCart, updateRestoInfo, setFlushCart, updateCartPickupDate, applyDiscount, updateCartFees, setCartTotalConfirm } from '../actions/cartAction'
import { updateRestoSettings, updateDeliverySchedule, updateOrderSchedule, updateDeliverySettings, updateTakeawaySettings } from '../actions/settingsAction';
import Lottie from 'react-lottie'
import Swal from 'sweetalert2';
import * as animationData from '../assets/loading.json'

import ProductDetail from './ProductDetail'
import { CartType } from '../flowObjectType'
import { computeDiscount } from './Utils'
import $ from "jquery"
import dayjs from 'dayjs'

import ProductCartRow from './ProductCartRow'
import { firestore } from '../services/firebase'
import '../styles/Cart.scss'
import TopBar from './TopBar'
import { setCustomerDetails } from '../actions/customerDetailsAction'

require('dayjs/locale/fr')
dayjs.locale('fr')
type Props = {
  match: Object,
  cart: CartType,
  customerDetails: Object,
  updateComment: Function,
  updateType: Function,
  updateRestoInfo: Function,
  setCustomerDetails: Function,
  updateRestoSettings: Function,
  updateOrderSchedule: Function,
  updateDeliverySchedule: Function,
  updateDeliverySettings: Function,
  updateTakeawaySettings: Function,
  resetCart: Function,
  settings: Object,
  applyDiscount: Function,
  setCartTotalConfirm: Function,
  setFlushCart: Function
}

type State = {
  modalVisible: boolean,
  showProductDetail: boolean,
  orderType: ?number,
  selectedIdx: number,
  userComment: string,
  showOrderConfirmed: boolean,
  loading: boolean,
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

class Cart extends Component<Props, State> {
  unsubscribe: Object
  refRestaurant: Object
  id: string
  commentRef: Object
  userId: string
  code: Object
  canPreOrder: boolean

  constructor(props) {
    super(props);
    this.commentRef = React.createRef()
    this.userId = ''
    this.code = null
    this.refRestaurant = null

    this.state = {
      showProductDetail: false,
      modalVisible: false,
      orderType: this.props.cart.orderType,
      selectedIdx: 0,
      userComment: '',
      showOrderConfirmed: false,
      loading: false,
      couponCode: '',
    }

    this.canPreOrder = this.state.orderType === 1 ? (this.props.settings.takeawaySettings.preOrder === undefined || this.props.settings.takeawaySettings.preOrder) :
      this.state.orderType === 4 ? (this.props.settings.deliverySettings.preOrder === undefined || this.props.settings.deliverySettings.preOrder) : true

  }

  componentDidMount() {
    if (this.props.cart.flushCart === true) this.props.resetCart()
    const orderType = this.props.cart.orderType
    $(window).scrollTop(0)

    if (this.props.cart.restoId) {
      this.refRestaurant = firestore().collection('Restaurant').doc(this.props.cart.restoId)
      this.unsubscribe = this.refRestaurant.onSnapshot(this.onRestoUpdate)
    }

    this.setState({ orderType })

    setInterval(() => {
      this.forceUpdate()
    }, 10000)
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe()
  }

  onRestoUpdate = (querySnapshot: Object) => {
    const restaurant = querySnapshot.data()

    if (!restaurant.settings.delivery && !restaurant.settings.takeaway && !restaurant.settings.table) {
      this.handleOrderTypeChange(0)
    }

    this.props.updateRestoInfo(restaurant.informations.id, restaurant.informations.name, restaurant.informations.postalCode, restaurant.state.canOrder, restaurant.informations)
    this.props.updateRestoSettings(restaurant.informations.id, restaurant.settings.color, restaurant.settings.autoAcceptOrder, restaurant.settings.delivery, restaurant.settings.takeaway, restaurant.settings.table, restaurant.settings.stripe || '', restaurant.settings.orderTypePopup || false);

    this.canPreOrder = this.state.orderType === 1 ? (this.props.settings.takeawaySettings.preOrder === undefined || this.props.settings.takeawaySettings.preOrder) :
      this.state.orderType === 4 ? (this.props.settings.deliverySettings.preOrder === undefined || this.props.settings.deliverySettings.preOrder) : true
  }

  onClickProduct = (index) => e => {
    this.setState({
      showProductDetail: true,
      selectedIdx: index
    });
  };

  toggleModal = () => {
    this.setState(prevState => ({
      showProductDetail: !prevState.showProductDetail
    }))
  }

  onUpdateComment = () => {
    //redux
    this.props.updateComment(this.state.userComment)
  }

  getFinalProduct = () => {
    const { products } = this.props.cart
    const newProduct = []
    products.forEach((product, index) => {
      const { rubric, ...noRubric } = product
      newProduct[index] = { ...noRubric, rubric: [] };
      (product.rubric || []).forEach((sections) => {
        const rubricData = sections.data.filter((item) => item.checked === true)
        newProduct[index].rubric.push({ rubricName: sections.rubricName, data: rubricData })
      })
    })
    return newProduct
  }

  _getVatDetail = (products) => {
    let vatDetail = {}
    products.forEach(product => {

      let total = product.quantity * product.unitPrice
      let totalHT = product.quantity * product.unitPriceHT
      if (typeof product.discount !== 'undefined' && product.discount !== null && !product.discount.isFidelityDiscount) {
        total = total * (1 - product.discount.percentage / 100)
        totalHT = totalHT * (1 - product.discount.percentage / 100)
      }

      const productVat = total - totalHT
      if (product.vat !== '' && !isNaN(productVat)) {
        if (typeof vatDetail[product.vat] === 'undefined')
          vatDetail[product.vat] = productVat
        else
          vatDetail[product.vat] += productVat
      }

      product.rubric.forEach((section) => {
        section.data.forEach((rubric) => {
          if (rubric.unitPrice > 0) {
            const rubricVat = rubric.unitPrice - rubric.unitPriceHT
            if (rubric.vat !== '' && !isNaN(rubricVat)) {
              if (typeof vatDetail[rubric.vat] === 'undefined')
                vatDetail[rubric.vat] = rubricVat
              else
                vatDetail[rubric.vat] += rubricVat
            }
          }
        })
      })
    })
    return vatDetail
  }

  _submitForm = (event) => {
    if (event && event.preventDefault)
      event.preventDefault()

    if (typeof this.props.cart.canOrder === 'undefined' || this.props.cart.canOrder === false) {
      Swal.fire("Attention", "La commande sur internet est momentanement suspendu", "error");
      return
    }

    this.setState({ loading: true });
    return this.confirmCart(false);
  }

  confirmCart = async (redirect = false) => {
    let { total, totalHT, totalVat, totalProducts } = this.props.cart
    const products = this.getFinalProduct()
    const restoId = this.props.cart.restoId
    const qrId = this.props.cart.qrId
    const orderDate = new Date();

    const jsonOrder = {
      createdAt: orderDate,
      confirmedAt: orderDate,
      comment: this.state.userComment,
      products: Array.from(products),
      productNbr: totalProducts,
      status: 2,
      total,
      totalHT,
      totalVat,
      vatDetail: this._getVatDetail(products),
      userName: "",
      orderType: 3
    }

    this.props.setCartTotalConfirm(total);
    const refRestoOrder = firestore().collection(`Restaurant/${restoId}/QRCode/`).doc(qrId)
    refRestoOrder.update({
      createdAt: orderDate,
      order: firestore.FieldValue.arrayUnion(jsonOrder),
      status: 2,
      confirmedAt: orderDate,
    })

    setTimeout(() => {
      this.props.closeModal()
      this.setState({ loading: false });
      this.props.resetCart();
      Swal.fire("Commande validée", "Vous serez bientôt servis", "success");
    }, 1000)


  }

  handleOrderTypeChange = (orderType) => {
    this.props.updateType(orderType)
    this.props.applyDiscount(orderType === 1 ? this.props.settings.takeawaySettings.discount || 0 : orderType === 4 ? this.props.settings.deliverySettings.discount || 0 : 0);
    this.setState({ orderType });

    this.canPreOrder = this.state.orderType === 1 ? (this.props.settings.takeawaySettings.preOrder === undefined || this.props.settings.takeawaySettings.preOrder) :
      this.state.orderType === 4 ? (this.props.settings.deliverySettings.preOrder === undefined || this.props.settings.deliverySettings.preOrder) : true
  }

  closeProductModal = () => {
    this.setState({ showProductDetail: false })
  }

  getOrderTotal = () => {
    let total = this.props.cart.total;
    if (this.props.cart.coupon !== null) {
      if (this.props.cart.coupon?.discountType === 'percent') {
        total = Number.parseFloat(computeDiscount(total, this.props.cart.coupon.discountPercent, true).toFixed(2));
      }
      else if (this.props.cart.coupon?.discountType === 'price') {
        total -= this.props.cart.coupon.discountPrice;
      }
    }
    return total;
  }

  getMinimumPrice = () => {
    if (this.state.userPostal !== null) {
      return (
        this.state.orderType === 1 ? this.props.settings.takeawaySettings.minimumPrice || 0 :
          this.state.orderType === 4 ? this.props.settings.deliverySettings.deliveryPostal[this.state.userPostal].postalMin || 0 : 0
      );
    }
    return this.state.orderType === 1 ? this.props.settings.takeawaySettings.minimumPrice || 0 : 0;
  }

  render() {
    const confirmDisable = this.props.cart.products.length === 0

    if (this.state.showOrderConfirmed)
      return (<Redirect to={`confirm`} />)

    return (
      <Container className="container" id="checkout">
        {
          this.props.settings.color && (
            <style> {`body {
               --website-color: ${this.props.settings.color}
             }`}
            </style>
          )
        }
        <Breakpoint medium up>
          <TopBar match={this.props.match} closeModal={this.props.closeModal} />
          <Form className="form">
            <div className="left-block">
              <div className="cart-my-order">
                <div className="cart-title">
                  Commentaire
                </div>
              </div>

              <Form.Group controlId="exampleForm.ControlTextarea1" className="comment-block">
                <Form.Control
                  as="textarea"
                  rows="3"
                  type="text"
                  placeholder="Ajouter un commentaire (facultatif)"
                  value={this.state.userComment}
                  maxLength="120"
                  onChange={(t) => this.setState({ userComment: t.target.value })}
                />
              </Form.Group>
            </div>

            <div className="cart">
              <div className="cart-product-container">
                {this.props.cart.products.map((product, index) =>
                  <ProductCartRow product={product} key={index} index={index} onClick={this.onClickProduct} />
                )}
              </div>

              <div className="cart-total-container">
                <div className="cart-row" style={{ width: '100%', justifyContent: 'space-between' }}>
                  <div className="cart-title">TOTAL</div>
                  <div className="cart-title">
                    {this.getOrderTotal().toFixed(2)} €
                  </div>
                </div>
              </div>

              <div className="footer">
                <button
                  type="submit"
                  id="recaptcha-container"
                  onClick={this._submitForm}
                  disabled={confirmDisable}
                >
                  <div className={`confirm-btn ${confirmDisable ? ' disabled' : ''}`}>Confirmez ma commande</div>
                </button>
              </div>
            </div>
          </Form>
        </Breakpoint>

        <Breakpoint small down>
          <TopBar match={this.props.match} closeModal={this.props.closeModal} />

          <div className="cart-product-container">
            {this.props.cart.products.map((product, index) =>
              <ProductCartRow product={product} key={index} index={index} onClick={this.onClickProduct} />
            )}
          </div>

          <div className="cart-total-container">
            <div className="cart-row" style={{ width: '100%', justifyContent: 'space-between' }}>
              <div className="cart-title">TOTAL</div>
              <div className="cart-title">{this.getOrderTotal().toFixed(2)} €</div>
            </div>
          </div>


          <Form autoComplete="off">
            <div className="cart-my-order">
              <div className="cart-title">
                Commentaire
              </div>
            </div>


            <Form.Group controlId="exampleForm.ControlTextarea1" className="comment-block">
              <Form.Control
                as="textarea"
                rows="3"
                type="text"
                placeholder="Ajouter un commentaire (facultatif)"
                value={this.state.userComment}
                onChange={(t) => this.setState({ userComment: t.target.value })}
              />
            </Form.Group>


            <div className="footer">
              <button
                type="submit"
                id="recaptcha-container"
                onClick={this._submitForm}
                disabled={confirmDisable}
              >
                <div className={`confirm-btn ${confirmDisable ? ' disabled' : ''}`}>Confirmez ma commande</div>
              </button>
            </div>
          </Form>

        </Breakpoint>

        <Modal
          show={this.state.showProductDetail}
          className="custom-map-modal"
          aria-labelledby="example-custom-modal-styling-title"
          size="lg"
          onHide={this.closeProductModal}
        >
          {this.props.cart.products.length > 0 && (
            <ProductDetail
              closeModal={this.toggleModal}
              cart={this.props.cart}
              product={this.props.cart.products[this.state.selectedIdx]}
              updateIndex={this.state.selectedIdx}
            />
          )}
        </Modal>

        <Modal
          show={this.state.loading}
          onHide={() => null}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Lottie
            options={defaultOptions}
          />
        </Modal>

      </Container >
    )
  }
}

//redux

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    settings: state.settings,
    customerDetails: state.customerDetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateComment: (comment) => dispatch(updateComment(comment)),
    updateType: (type) => dispatch(updateType(type)),
    updateRestoSettings: (a, b, c, d, e, f, g, h) => dispatch(updateRestoSettings(a, b, c, d, e, f, g, h)),
    resetCart: () => dispatch(resetCart()),
    updateRestoInfo: (a, b, c, d, e) => dispatch(updateRestoInfo(a, b, c, d, e)),
    setCustomerDetails: (customerDetails) => dispatch(setCustomerDetails(customerDetails)),
    updateOrderSchedule: (schedule) => dispatch(updateOrderSchedule(schedule)),
    updateDeliverySchedule: (schedule) => dispatch(updateDeliverySchedule(schedule)),
    updateDeliverySettings: (s) => dispatch(updateDeliverySettings(s)),
    updateTakeawaySettings: (s) => dispatch(updateTakeawaySettings(s)),
    updateCartPickupDate: (t) => dispatch(updateCartPickupDate(t)),
    applyDiscount: (d) => dispatch(applyDiscount(d)),
    setCartTotalConfirm: (total) => dispatch(setCartTotalConfirm(total)),
    updateCartFees: (discount, fees) => dispatch(updateCartFees(discount, fees)),
    setFlushCart: () => dispatch(setFlushCart()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
