
// @flow
import React from 'react'
import { FaArrowLeft } from "react-icons/fa"
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom"
import '../styles/TopBar.scss'

export default function TopBar(props) {
  return (
    <div className="top-bar">
      {props.leftButton && (
        <Link
          to={props.backLink}
        >
          <div className="back-btn">
            <FaArrowLeft size="2em" color={'#000'} />
          </div>
        </Link>
      )}

      {props.closeModal && (
        <div className="back-btn" onClick={props.closeModal}>
          <MdClose size="2em" color={'#000'} />
        </div>
      )}
      {!props.leftButton && (<div />)}
      <div className="page-title">Passer la commande</div>
      <div />
    </div>
  )
}