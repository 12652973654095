export const SET_RECOMMENDATION1 = "SET_RECOMMENDATION1"
export const SET_RECOMMENDATION2 = "SET_RECOMMENDATION2"
export const FLUSH_RECOMMENDATIONS = "FLUSH_RECOMMENDATIONS"
export const SET_HAS_REFUSED = "SET_HAS_REFUSED"
export const INCREMENT_RECOMMENDATION_COUNT = "INCREMENT_RECOMMENDATION_COUNT"

export function setRecommendation1(recommendation1) {
  return {
    type: SET_RECOMMENDATION1,
    payload: {
      recommendation1,
    }
  }
}

export function setRecommendation2(recommendation2) {
  return {
    type: SET_RECOMMENDATION2,
    payload: {
      recommendation2,
    }
  }
}

export function setHasRefused() {
  return {
    type: SET_HAS_REFUSED,
    payload: { }
  }
}

export function incrementRecommendationCount() {
  return {
    type: INCREMENT_RECOMMENDATION_COUNT,
    payload: { }
  }
}

export function flushRecommendations() {
  return {
    type: FLUSH_RECOMMENDATIONS,
    payload: { }
  }
}