/**
 * @format
 * @flow
 */

import {
  UPDATE_COLORS,
} from '../actions/colorsAction';


// const colorsObject = {
//   main: '#077DF4',
//   mainText: '#fff',
//   categoryBg: '#f9f9f9',
//   categoryBtnSelected: '#077DF4',
//   categoryText: '#000',
//   categoryTextSelected: '#fff',
//   productBg: '#f1f1f1',
//   imgBorder: '#fff',
//   categoryName: '#000',
//   productBtn: '#fff',
//   productName: '#000',
//   productDesciption: '#000',
//   productBorder: '#d9d9d9',
//   productPrice: '#000',
// }

const colorsObject = {
  mainBg: '#fff',
  main: '#077DF4',
  mainText: '#000',
  categoryBg: '#f9f9f9',
  categoryBtnSelected: '#077DF4',
  categoryText: '#000',
  categoryTextSelected: '#fff',
  productBg: '#fff',
  imgBorder: '#fff',
  categoryName: '#000',
  productBtn: '#fff',
  productName: '#000',
  productDescription: '#383d41',
  productBorder: '#d9d9d9',
  productPrice: '#000',
}

const darkColorsObject = {
  mainBg: '#000',
  main: '#000',
  mainText: '#fff',
  categoryBg: '#000',
  categoryBtnSelected: '#077DF4',
  categoryText: '#fff',
  categoryTextSelected: '#fff',
  productBg: '#000',
  imgBorder: '#fff',
  categoryName: '#000',
  productBtn: '#fff',
  productName: '#fff',
  productDescription: '#bbab94',
  productBorder: '#3b3b3a',
  productPrice: '#fff',
}



export default function foo(state: Object = { ...colorsObject }, action: Object) {
  switch (action.type) {
    case UPDATE_COLORS:
      return {
        ...state,
        mainBg: action.payload.useDarkTheme ? darkColorsObject.mainBg : colorsObject.mainBg,
        main: action.payload.useDarkTheme ? darkColorsObject.main : colorsObject.main,
        mainText: action.payload.useDarkTheme ? darkColorsObject.mainText : colorsObject.mainText,
        categoryBg: action.payload.useDarkTheme ? darkColorsObject.categoryBg : colorsObject.categoryBg,
        categoryBtnSelected: action.payload.useDarkTheme ? darkColorsObject.categoryBtnSelected : colorsObject.categoryBtnSelected,
        categoryText: action.payload.useDarkTheme ? darkColorsObject.categoryText : colorsObject.categoryText,
        categoryTextSelected: action.payload.useDarkTheme ? darkColorsObject.categoryTextSelected : colorsObject.categoryTextSelected,
        productBg: action.payload.useDarkTheme ? darkColorsObject.productBg : colorsObject.productBg,
        imgBorder: action.payload.useDarkTheme ? darkColorsObject.imgBorder : colorsObject.imgBorder,
        categoryName: action.payload.useDarkTheme ? darkColorsObject.categoryName : colorsObject.categoryName,
        productBtn: action.payload.useDarkTheme ? darkColorsObject.productBtn : colorsObject.productBtn,
        productName: action.payload.useDarkTheme ? darkColorsObject.productName : colorsObject.productName,
        productDescription: action.payload.useDarkTheme ? darkColorsObject.productDescription : colorsObject.productDescription,
        productBorder: action.payload.useDarkTheme ? darkColorsObject.productBorder : colorsObject.productBorder,
        productPrice: action.payload.useDarkTheme ? darkColorsObject.productPrice : colorsObject.productPrice,
      }
    default:
      return state;
  }
}
