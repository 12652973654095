/**
 * @format
 * @flow
 */

import {
  UPDATE_SETTINGS,
  UPDATE_DELIVERY_SCHEDULE,
  UPDATE_ORDER_SCHEDULE,
  UPDATE_DELIVERY_SETTINGS,
  UPDATE_TAKEAWAY_SETTINGS
} from '../actions/settingsAction';
import { SettingsType } from '../flowObjectType'

const settingsObject: SettingsType = {
  id: '',
  color: '',
  autoAcceptOrder: false,
  delivery: false,
  takeaway: true,
  table: false,
  deliverySettings: {},
  takeawaySettings: {},
  stripe: {},
  orderTypePopup: false
}

export default function foo(state: Object = { ...settingsObject }, action: Object) {
  switch (action.type) {
    case UPDATE_SETTINGS:
      return {
        ...state,
        id: action.payload.id,
        color: action.payload.color,
        autoAcceptOrder: action.payload.autoAcceptOrder,
        delivery: action.payload.delivery,
        takeaway: action.payload.takeaway,
        table: action.payload.table,
        stripe: action.payload.stripe,
        orderTypePopup: action.payload.orderTypePopup
      }
    case UPDATE_ORDER_SCHEDULE:
      return { ...state, orderSchedule: action.payload.orderSchedule };
    case UPDATE_DELIVERY_SCHEDULE:
      return { ...state, deliverySchedule: action.payload.deliverySchedule };
    case UPDATE_DELIVERY_SETTINGS:
      return { ...state, deliverySettings: action.payload.deliverySettings };
    case UPDATE_TAKEAWAY_SETTINGS:
      return { ...state, takeawaySettings: action.payload.takeawaySettings };
    default:
      return state;
  }
}
